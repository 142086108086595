import { countEntities, createApiError, isEntityExists,
         BASE_URL, throwIfResponseNotOk, get, del, tryDeleteEntity } from "api/http/util"

import { PageRequest } from "api/requests"
import { DocumentResponseSchema, PageResponse, createPageResponseSchema } from "api/responses"
import { Document } from "model"
import { joinObjectSearchParams, joinSubpaths, Nullish } from "my-util"

export const DOCUMENTS_SUBPATH = "/documents"

// Count

export async function countDocuments(signal?: AbortSignal | null): Promise<number> {
    return countEntities(DOCUMENTS_SUBPATH, signal)
}

export async function countMyDocuments(signal?: AbortSignal | null): Promise<number> {
    return countEntities(joinSubpaths([DOCUMENTS_SUBPATH, "/my"]), signal)
}

// Exists

export async function isDocumentExistsById(id: string, signal?: AbortSignal | null) {
    return isEntityExists(joinSubpaths([DOCUMENTS_SUBPATH, "/my"]), signal)
}

// Get one

export async function getDocumentById(id: string, signal?: AbortSignal | null): Promise<Document> {
    return new Document(await get({
        subpath: joinSubpaths([DOCUMENTS_SUBPATH, id]),
        schema: DocumentResponseSchema,
        signal,
    }))
}

export async function getDocumentDataById(id: string, signal?: AbortSignal | null): Promise<Blob> {
    const url = createDocumentDownloadUrlById(id)
    const response = await fetch(url, { signal })

    await throwIfResponseNotOk(response)

    return response.blob()
}

// Download by ID

export function downloadDocumentById(id: string, filename: string = "unknown") {
    const a = document.createElement("a")
    const url = createDocumentDownloadUrlById(id)

    a.href = url
    a.download = filename

    a.click()
    a.remove()
}

export function createDocumentDownloadUrlById(id: string): string {
    return BASE_URL + joinSubpaths([DOCUMENTS_SUBPATH, id, "/data"])
}

// Get many

export async function getAllDocuments(request: PageRequest = {}): Promise<PageResponse<Document>> {
    return getAllDocumentsAt(
        DOCUMENTS_SUBPATH + "?" + joinObjectSearchParams({
            max: request.max,
            offset: request.offset,
        }),

        request.signal,
    )
}

export async function getAllMyDocuments(request: PageRequest = {}): Promise<PageResponse<Document>> {
    return getAllDocumentsAt(
        DOCUMENTS_SUBPATH + "/my?" + joinObjectSearchParams({
            max: request.max,
            offset: request.offset,
        }),

        request.signal,
    )
}

async function getAllDocumentsAt(
    subpath: string,
    signal?: AbortSignal | null,
): Promise<PageResponse<Document>> {
    const response = await get({
        subpath: subpath,
        schema: createPageResponseSchema(DocumentResponseSchema),
        signal,
    })

    return {
        ...response,
        content: response.content.map(response => new Document(response)),
    }
}

// Delete many

export async function clearDocuments(signal?: AbortSignal | null) {
    return clearDocumentsAt("", signal)
}

export async function clearMyDocuments(signal?: AbortSignal | null) {
    return clearDocumentsAt("/my", signal)
}

async function clearDocumentsAt(subpath: string, signal?: AbortSignal | null) {
    return del({
        subpath: joinSubpaths([DOCUMENTS_SUBPATH, subpath]),
        signal,
    })
}

// Delete one

export async function deleteDocumentById(id: string, signal?: AbortSignal | null) {
    return del({
        subpath: joinSubpaths([DOCUMENTS_SUBPATH, id]),
        signal,
    })
}

export async function tryDeleteDocumentById(
    id: string,
    signal?: AbortSignal | null,
): Promise<boolean> {
    return tryDeleteEntity(joinSubpaths([DOCUMENTS_SUBPATH, id]), signal)
}

// Upload

export interface UploadDocumentsOptions {
    onProgress: (loaded: number, total: number) => void
    onAbort: (reason?: string) => void
    onError: (error: unknown) => void
    onSuccess: (documents: Document[]) => void

    signal: AbortSignal
}

export function uploadDocuments(
    files: File[],

    {
        onProgress, onAbort, onError, onSuccess,
        signal,
    }: Readonly<Nullish<UploadDocumentsOptions>> = {},
) {
    const formData = createFormData()
    const request = createOpenRequest()

    addEventListenersToRequest(request)

    request.send(formData)

    function createFormData(): FormData {
        const formData = new FormData()

        for (const file of files)
            formData.append("files", file, file.name)

        return formData
    }

    function createOpenRequest(): XMLHttpRequest {
        const request = new XMLHttpRequest()

        request.responseType = "text"

        const url = BASE_URL + DOCUMENTS_SUBPATH

        request.open("POST", url)

        return request
    }

    function addEventListenersToRequest(request: XMLHttpRequest) {
        // Error

        request.onerror = () => {
            onError?.(createApiError(
                request.status,
                request.statusText,
                "Failed to perform a request"
            ))
        }

        // Abort

        if (signal != null) {
            signal.addEventListener("abort", () => request.abort())
            request.onabort = () => onAbort?.(signal.reason)
        }

        // Progress

        if (onProgress)
            request.upload.onprogress = event => {
                if (event.lengthComputable)
                    onProgress(event.loaded, event.total)
            }

        // Load

        request.onload = () => {
            try {
                if (request.status < 200 || request.status >= 300)
                    throw createApiError(
                        request.status,
                        request.statusText,
                        request.responseText,
                    )

                const json = JSON.parse(request.responseText)
                const allParsedDocuments = DocumentResponseSchema.array().parse(json)
                const documents = allParsedDocuments.map(parsedDocument => new Document(parsedDocument))

                onSuccess?.(documents)
            } catch (error) {
                onError?.(error)
            }
        }
    }
}
