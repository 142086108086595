import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { putSubsystemById, SubsystemRequest } from "api"
import { Subsystem } from "model"
import { DeepReadonly } from "my-util"
import { SubsystemActionModal } from "../SubsystemActionModal"

export namespace SubsystemEditingModal {
    export interface Props {
        onSaved?: (subsystem: Subsystem) => void
        onClose?: () => void

        subsystem: Subsystem

        closeOnSuccess?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubsystemEditingModal = forwardRef((
    {
        onSaved, onClose,
        subsystem,
        closeOnSuccess,
        width,
    }: DeepReadonly<SubsystemEditingModal.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <SubsystemActionModal header={t("domain.subsystems.headers.editing")}
                                 yes={t("misc.actions.save")}
                                 no={t("misc.actions.cancel")}

                                 onYes={onInnerSave}
                                 onNo={onClose}

                                 subsystem={subsystem}

                                 closeOnSuccess={closeOnSuccess}

                                 apiErrorMessageMapping={{
                                     409: t("domain.subsystems.messages.errors.keyDuplicate")
                                 }}

                                 width={width}

                                 ref={ref}>

    </SubsystemActionModal>

    async function onInnerSave(subsystem: Subsystem) {
        const request: SubsystemRequest = {
            key: subsystem.key,
            name: subsystem.name,
            address: subsystem.address,
        }

        const savedSubsystem = await putSubsystemById(subsystem.id, request)

        onSaved?.(savedSubsystem)
    }
})

SubsystemEditingModal.displayName = "SubsystemEditingModal"
