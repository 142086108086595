import { z } from "zod"
import { ModelObjectResponseSchema } from "../model-object"

export const SubsystemResponseSchema = ModelObjectResponseSchema.extend({
    key: z.coerce.string(),
    name: z.string().nullish(),
    address: z.string().nullish(),
})

export type SubsystemResponse = z.infer<typeof SubsystemResponseSchema>
