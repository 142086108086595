export function arrayOfNonNull<T>(...items: T[]): NonNullable<T>[] {
    return items.filter(item => item != null) as NonNullable<T>[]
}

export function generateArray<T>(
    length: number,
    generateItem: (index: number, length: number) => T,
): T[] {
    const result: T[] = []

    for (let index = 0; index < length; ++index)
        result.push(generateItem(index, length))

    return result
}
