import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from "react"
import { Flex } from "ui/ui/layout"
import { Label, Information } from "ui/ui/output"
import { Icon } from "ui/ui/icon"
import style from "./style.module.css"

export namespace Output {
    export interface Props {
        children?: ReactNode

        left?: ReactNode
        right?: ReactNode

        width?: string
        height?: string
        valueHeight?: string
        minHeight?: string

        color?: string
        backgroundColor?: string
        borderColor?: string

        resize?: CSSProperties["resize"]
        whiteSpace?: CSSProperties["whiteSpace"]
        wordBreak?: CSSProperties["wordBreak"]
        overflow?: CSSProperties["overflow"]

        label?: string
        wrapLabel?: boolean
        information?: string

        iconSrc?: string
        iconAlt?: string
        iconFilter?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Output = forwardRef((
    {
        children,
        left, right,
        width, height, valueHeight, minHeight,
        color, backgroundColor, borderColor,
        resize, whiteSpace, wordBreak, overflow,
        label, wrapLabel, information,
        iconSrc, iconAlt, iconFilter,
    }: Readonly<Output.Props>,
    ref: ForwardedRef<HTMLDivElement>
) =>
    <Flex align="start"
          gap="4px"
          width={width}
          height={height}
          ref={ref}>
        <Label wrap={wrapLabel}
               fontSize="16px"
               text={label}/>

        <div className={style.childrenWrapper}
             style={{ height: valueHeight, minHeight }}>
            {left &&
                <div className={style.left}>
                    {left}
                </div>
            }

            <div style={{ color, backgroundColor, borderColor, resize, whiteSpace, wordBreak, overflow, minHeight }}
                 className={style.children}>
                {children}

                {iconSrc != null &&
                    <div className={style.icon}>
                        <Icon src={iconSrc}
                              alt={iconAlt}
                              filter={iconFilter}
                              width="1em"
                              height="1em"/>
                    </div>
                }
            </div>

            {right &&
                <div className={style.right}>
                    {right}
                </div>
            }
        </div>

        <Information text={information}
                     fontSize="16px"/>
    </Flex>
)

Output.displayName = "Output"
