import { ReactNode, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { plusIconUrl } from "images"
import { deleteSubsystemById, getAllSubsystems } from "api"
import { Subsystem } from "model"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import { Page, SubsystemCreationModal, SubsystemEditingModal, SubsystemTable } from "ui/component"
import { ActionModal, Button, ErrorDisplay, Flex, Loading, Padding } from "ui/ui"

export function Component() {
    const [t] = useTranslation()
    const [localUser] = useContext(UserContext)

    // State

    const [subsystems, setSubsystems] = useState<Subsystem[]>([])
    const [loading, setLoading] = useState(localUser?.isSuperAdmin ?? false)
    const [error, setError] = useState<unknown>(undefined)

    const [deletingId, setDeletingId] = useState<string | undefined>(undefined)
    const [editingId, setEditingId] = useState<string | undefined>(undefined)
    const [creating, setCreating] = useState(false)

    // Effects

    useEffect(
        () => {
            if (!loading)
                return

            const controller = new AbortController()

            getAllSubsystems(controller.signal)
                .then(setSubsystems)
                .catch(error => {
                    if (!controller.signal.aborted)
                        setError(error)
                })
                .finally(() => {
                    if (!controller.signal.aborted)
                        setLoading(false)
                })

            return () => controller.abort()
        },

        [loading],
    )

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.isSuperAdmin)
        return <Error403Page/>

    return <Page title={renderCompactTitle()}
                 type="main">
        {renderContent()}

        {renderCreationModalIfCreating()}
        {renderDeletionModalIfDeleting()}
        {renderEditingModalIfEditing()}
    </Page>

    function renderCompactTitle(): ReactNode {
        return <Flex direction="row"
                     gap="8px">
            <Button onClick={() => setCreating(true)}

                    iconSrc={plusIconUrl}
                    iconAlt="Plus icon"

                    width="32px"/>

            {t("sections.subsystems.header").toUpperCase()}
        </Flex>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error != null)
            return <ErrorDisplay error={error}/>

        if (subsystems.length === 0)
            return <Padding paddingTop="22px">
                {t("sections.subsystems.messages.no")}
            </Padding>

        return <SubsystemTable onDelete={({ id }) => setDeletingId(id)}
                               onEdit={({ id }) => setEditingId(id)}

                               subsystems={subsystems}/>
    }

    function renderCreationModalIfCreating(): ReactNode {
        if (!creating)
            return null

        return <SubsystemCreationModal onCreated={newSubsystem => setSubsystems([...subsystems, newSubsystem])}
                                       onClose={() => setCreating(false)}
                                       width="calc(min(80vw, 400px))"
                                       closeOnSuccess/>
    }

    function renderDeletionModalIfDeleting(): ReactNode {
        if (deletingId == null)
            return

        return <ActionModal onNo={() => setDeletingId(undefined)}
                            onYes={onDelete}
                            closeOnSuccess
                            critical>
            {t("domain.subsystems.messages.warnings.deletion")}
        </ActionModal>

        async function onDelete() {
            await deleteSubsystemById(deletingId!)
            setSubsystems(oldSubsystems => oldSubsystems.filter(({ id }) => id !== deletingId))
        }
    }

    function renderEditingModalIfEditing(): ReactNode {
        if (editingId == null)
            return null

        const subsystem = subsystems.find(({ id }) => id === editingId)

        if (subsystem == null)
            return null

        return <SubsystemEditingModal onSaved={onSaved}
                                      onClose={() => setEditingId(undefined)}
                                      subsystem={subsystem}
                                      width="calc(min(80vw, 400px))"
                                      closeOnSuccess/>

        function onSaved(newSubsystem: Subsystem) {
            setSubsystems(oldSubsystems => oldSubsystems.map(oldProvider =>
                oldProvider.id === newSubsystem.id
                    ? newSubsystem
                    : oldProvider
            ))
        }
    }
}
