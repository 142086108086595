import { CSSProperties, ForwardedRef, forwardRef } from "react"

export namespace Label {
    export interface Props {
        text?: string
        fontWeight?: CSSProperties["fontWeight"]
        fontSize?: CSSProperties["fontSize"]
        wrap?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Label = forwardRef((
    {
        text,
        fontWeight,
        fontSize,
        wrap,
    }: Readonly<Label.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    return <span
        style={{
            display: text ? undefined : "none",
            fontSize: fontSize ?? "16px",
            fontWeight: fontWeight ?? "normal",
            whiteSpace: wrap ? undefined : "nowrap",
        }}
        ref={ref}
    >
        {text}
    </span>
})

Label.displayName = "Label"
