import { ReactNode, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { getAllDocuments, getAllMyDocuments, getAllUsersByIds } from "api"
import { User } from "model"
import { uniqueArray } from "my-util"
import { useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import { DocumentTable, Page } from "ui/component"
import { Padding, PagedView } from "ui/ui"

const PAGE_SIZE = 20

export function Component() {
    // State

    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedUsers = useUsers()

    const [totalPages, setTotalPages] = useState(0)

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page title={t("sections.documents.header").toUpperCase()}
                 type="main">
        <PagedView renderPage={renderPage}
                   total={totalPages}
                   align="start"
                   justify="space-between"
                   height="100%"/>
    </Page>

    async function renderPage(index: number, total: number, signal: AbortSignal): Promise<ReactNode> {
        // Documents

        const getDocuments = localUser?.isManager
            ? getAllDocuments
            : getAllMyDocuments

        const documentsPage = await getDocuments({
            max: PAGE_SIZE,
            offset: index,
            signal,
        })

        if (documentsPage.totalPages != null)
            setTotalPages(documentsPage.totalPages)

        const documents = documentsPage.content

        // Creators

        let creators: User[] | undefined

        if (localUser?.isManager) {
            const creatorIds = uniqueArray(
                documents
                    .map(({ creatorId }) => creatorId)
                    .filter(id => id != null) as string[]
            )

            creators = await getAllUsersByIds({ ids: creatorIds }, signal)

            storedUsers.addAll(creators)
        } else
            creators = undefined

        // Render

        if (documents.length === 0)
            return <Padding paddingTop="22px">
                {t("sections.documents.messages.no")}
            </Padding>

        return <DocumentTable documents={documents}
                              users={creators}
                              showCreators={localUser?.isManager}/>
    }
}
