import { ForwardedRef, forwardRef, Fragment, ReactNode, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { providersSectionIconUrl,
         adminsSectionIconUrl, agentsSectionIconUrl,
         messagesSectionIconUrl, walletSectionIconUrl,
         archiveSectionIconUrl, calendarSectionIconUrl,
         clientsSectionIconUrl, documentsSectionIconUrl,
         subsystemsSectionIconUrl, transfersSectionIconUrl } from "images"

import { getLang } from "i18n"
import { UserContext } from "ui/context"
import * as AllTransfersPage from "ui/page/sections/transfers/AllTransfersPage/path"
import * as CalendarPage from "ui/page/sections/calendar/CalendarPage/path"
import * as AllDocumentsPage from "ui/page/sections/documents/AllDocumentsPage/path"
import * as AllArchivedTransfersPage from "ui/page/sections/archive/AllArchivedTransfersPage/path"
import * as MessengerPage from "ui/page/sections/messenger/MessengerPage/path"
import * as AllAdminsPage from "ui/page/sections/admins/AllAdminsPage/path"
import * as AllClientsPage from "ui/page/sections/clients/AllClientsPage/path"
import * as AllAgentsPage from "ui/page/sections/agents/AllAgentsPage/path"
import * as AllProvidersPage from "ui/page/sections/providers/AllProvidersPage/path"
import * as AllSubsystemsPage from "ui/page/sections/subsystems/AllSubsystemsPage/path"
import * as WalletPage from "ui/page/sections/wallet/WalletPage/path"
import { Circle, Flex, Padding } from "ui/ui"
import { SectionLink } from "../SectionLink"
import style from "./style.module.css"

interface Link {
    to: string
    text?: string
    iconSrc?: string
}

export namespace SectionLinkList {
    export interface Props {
        short?: boolean

        firstRender?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SectionLinkList = forwardRef((
    {
        short,
        firstRender,
    }: Readonly<SectionLinkList.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    // State

    const linkGroups = useMemo<Link[][]>(() => {
        const linkGroups: Link[][] = [
            [
                {
                    to: AllTransfersPage.PATH,
                    text: t("sections.transfers.link"),
                    iconSrc: transfersSectionIconUrl,
                },
            ],

            [
                {
                    to: CalendarPage.PATH,
                    text: t("sections.calendar.link"),
                    iconSrc: calendarSectionIconUrl,
                },

                {
                    to: AllDocumentsPage.PATH,
                    text: t("sections.documents.link"),
                    iconSrc: documentsSectionIconUrl,
                },

                {
                    to: AllArchivedTransfersPage.PATH,
                    text: t("sections.archive.link"),
                    iconSrc: archiveSectionIconUrl,
                },

                {
                    to: MessengerPage.PATH,
                    text: t("sections.messenger.link"),
                    iconSrc: messagesSectionIconUrl,
                },
            ]
        ]

        if (localUser?.isAgent) {
            linkGroups[0].push({
                to: WalletPage.PATH,
                text: t("sections.wallet.link"),
                iconSrc: walletSectionIconUrl,
            })

            const lastGroupLinks: Link[] = []

            if (localUser.isManager)
                lastGroupLinks.push({
                    to: AllAdminsPage.PATH,
                    text: t("sections.admins.link"),
                    iconSrc: adminsSectionIconUrl,
                })

            if (localUser.isAgent)
                lastGroupLinks.push({
                    to: AllClientsPage.PATH,
                    text: t("sections.clients.link"),
                    iconSrc: clientsSectionIconUrl,
                })


            if (localUser.isManager)
                lastGroupLinks.push(
                    {
                        to: AllAgentsPage.PATH,
                        text: t("sections.agents.link"),
                        iconSrc: agentsSectionIconUrl,
                    },

                    {
                        to: AllProvidersPage.PATH,
                        text: t("sections.providers.link"),
                        iconSrc: providersSectionIconUrl,
                    },
                )

            linkGroups.push(lastGroupLinks)

            if (localUser.isSuperAdmin)
                linkGroups.push([{
                    to: AllSubsystemsPage.PATH,
                    text: t("sections.subsystems.link"),
                    iconSrc: subsystemsSectionIconUrl,
                }])
        }

        return linkGroups
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localUser, getLang()])

    // Render

    return <div className={renderClassName()}
                ref={ref}>
        {renderLinkGroups()}
    </div>

    function renderClassName(): string {
        if (firstRender)
            return short ? style.HiddenSectionLinkList : style.SectionLinkList

        if (short)
            return style.HidingSectionLinkList

        return style.AppearingSectionLinkList
    }

    function renderLinkGroups(): ReactNode {
        return <Flex align="start">
                {linkGroups.map((links, i) =>
                    <Fragment key={i}>
                        {i > 0 &&
                            <Padding paddingLeft="20px">
                                <Circle borderColor="red"
                                        color="red"
                                        radius="1px"/>
                            </Padding>
                        }

                        <Flex align="start">
                            {links.map(({ to, text, iconSrc }, j) =>
                                <SectionLink firstRender={firstRender}
                                             iconSrc={iconSrc}
                                             short={short}
                                             text={text}
                                             to={to}
                                             key={j}/>
                            )}
                        </Flex>
                    </Fragment>
                )}
            </Flex>
    }
})

SectionLinkList.displayName = "SectionLinkList"
