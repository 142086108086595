import { ReadonlyDate } from "my-util/type"

export function createOrCopyDate(date?: ReadonlyDate | null): Date {
    return date != null
         ? new Date(date.getTime())
         : new Date()
}

export function tryCopyDate(date?: ReadonlyDate | null): Date | null {
    return date != null
         ? new Date(date.getTime())
         : null
}

export function dateToLocalDateIsoString(date: ReadonlyDate): string {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const day = date.getDate().toString().padStart(2, "0")

    return `${year}-${month}-${day}T00:00:00.000Z`
}
