import assert from "assert"
import { CSSProperties, ForwardedRef, ReactNode, forwardRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { arrowsLeftRightIconUrl } from "images"
import { CompanyResponse, getCompanyByItn, getUserById } from "api"
import { getLang } from "i18n"
import { Money, Provider, User } from "model"
import { isNullOrBlank, DeepReadonly, normalizeUri } from "my-util"
import { useStateWithDeps, useUsers } from "ui/hook"
import { copyTransferFields, SignableDocumentFields, TransferFields } from "ui/fields"

import { DocumentListUpload, UserLink, DocumentUpload,
         SignableDocumentListEditor, ProductListEditor,
         RoutePointListEditor, UiDocument, isFieldDocument } from "ui/component"

import { Form, FormControls, Link, ErrorText, Output,
         Label, Flex, TransferDirectionRadio, MoneyOutput, Icon, Group,
         Subheader, TimeOutput, DecimalOutput, DateOutput, LoadingIndicator } from "ui/ui"

import { TransferProviderListEditor } from "../provider"
import style from "./style.module.css"

const MOBILE_FIELD_GAP = "4px"

export namespace TransferViewForm {
    export interface Props {
        onSubmit?: () => void
        onReset?: () => void
        onChange?: (fields: TransferFields) => void

        fields: TransferFields
        buttons?: FormControls.Button[]

        users?: Map<string, User> | Iterable<User>
        providers?: Map<string, Provider> | Iterable<Provider>

        loading?: boolean
        disabled?: boolean
        error?: unknown

        mobile?: boolean

        width?: CSSProperties["width"]
        height?: CSSProperties["height"]
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferViewForm = forwardRef((
    {
        onSubmit, onReset, onChange,
        fields, buttons,
        users, providers,
        loading, disabled, error,
        mobile,
        width, height,
    }: DeepReadonly<TransferViewForm.Props>,
    ref: ForwardedRef<HTMLFormElement>,
) => {
    const [t] = useTranslation()

    const usersStorage = useUsers()

    // State

    // - Users

    const [innerUsersById, setInnerUsersById] = useStateWithDeps<Map<string, User>>(
        oldUsersById => {
            const newUsersById = new Map(oldUsersById)
            const propsUsersById = User.groupByIdOrPassOrCreate(users)

            for (const [id, user] of propsUsersById)
                newUsersById.set(id, user)

            return newUsersById
        },

        [users],
    )

    // -- Creator

    const [loadingCreator, setLoadingCreator] = useStateWithDeps(
        () => fields.creatorId == null || !innerUsersById.has(fields.creatorId),
        [innerUsersById, fields],
    )

    const [creatorLoadingError, setCreatorLoadingError] = useState(undefined as unknown)

    // -- Agent

    const [loadingAgent, setLoadingAgent] = useStateWithDeps(
        () => {
            const { creatorId } = fields

            if (creatorId == null)
                return false

            const creator = innerUsersById.get(creatorId)

            if (creator?.creatorId == null)
                return

            return !innerUsersById.has(creator.creatorId)
        },

        [innerUsersById, fields],
    )

    const [agentLoadingError, setAgentLoadingError] = useState(undefined as unknown)

    // - Stopper

    const [loadingStopper, setLoadingStopper] = useStateWithDeps(
        () => {
            const { stopperId } = fields

            if (stopperId == null)
                return false

            return !innerUsersById.has(stopperId)
        },

        [innerUsersById, fields],
    )

    const [stopperLoadingError, setStopperLoadingError] = useState(undefined as unknown)

    // - Company

    const [company, setCompany] = useState(null as CompanyResponse | null)

    const [loadingCompany, setLoadingCompany] = useStateWithDeps(
        () => fields.companyItn != null,
        [fields.companyItn],
    )

    const [companyLoadingError, setCompanyLoadingError] = useState(undefined as unknown)

    // - Providers

    const [innerProvidersById, setInnerProvidersById] = useStateWithDeps<Map<string, Provider>>(
        oldProvidersById => {
            const newProvidersById = new Map(oldProvidersById)
            const propsProvidersById = Provider.groupByIdOrPassOrCreate(providers)

            for (const [id, provider] of propsProvidersById)
                newProvidersById.set(id, provider)

            return newProvidersById
        },

        [providers],
    )

    // Effects

    // - Creator loading

    useEffect(() => {
        if (!loadingCreator)
            return

        const { creatorId } = fields

        if (creatorId == null) {
            setLoadingCreator(false)
            return
        }

        const controller = new AbortController()

        getUserById(creatorId, controller.signal)
            .then(creator => {
                setInnerUsersById(oldUsersById => {
                    const newUsersById = new Map(oldUsersById)
                    newUsersById.set(creator.id, creator)
                    return newUsersById
                })

                usersStorage.add(creator)

                setLoadingAgent(creator.creatorId != null)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setCreatorLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingCreator(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCreator])

    // - Agent loading

    useEffect(() => {
        if (!loadingAgent)
            return

        const { creatorId } = fields

        const creator = creatorId != null
            ? innerUsersById.get(creatorId)
            : undefined

        const agentId = creator?.creatorId

        if (agentId == null) {
            setLoadingAgent(false)
            return
        }

        const controller = new AbortController()

        getUserById(agentId, controller.signal)
            .then(agent => {
                setInnerUsersById(oldUsersById => {
                    const newUsersById = new Map(oldUsersById)
                    newUsersById.set(agent.id, agent)
                    return newUsersById
                })

                usersStorage.add(agent)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setAgentLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingAgent(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAgent])

    // - Stopper loading

    useEffect(() => {
        if (!loadingStopper)
            return

        const { stopperId } = fields

        if (stopperId == null) {
            setLoadingStopper(false)
            return
        }

        const controller = new AbortController()

        getUserById(stopperId, controller.signal)
            .then(stopper => {
                setInnerUsersById(oldUsersById => {
                    const newUsersById = new Map(oldUsersById)
                    newUsersById.set(stopper.id, stopper)
                    return newUsersById
                })

                usersStorage.add(stopper)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setStopperLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingStopper(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingStopper])

    // - Company loading

    useEffect(() => {
        if (!loadingCompany)
            return

        if (fields.companyItn == null) {
            setLoadingCompany(false)
            return
        }

        const controller = new AbortController()

        getCompanyByItn(fields.companyItn, controller.signal)
            .then(setCompany)
            .catch(error => {
                if (!controller.signal.aborted)
                    setCompanyLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingCompany(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCompany])

    // Render

    return <Form onSubmit={onSubmit}
                 onReset={onReset}

                 loading={loading}

                 width={width}
                 height={height}

                 ref={ref}>
        <Flex justify="space-between"
              height="100%">
            {renderContent()}
            {renderControls()}
        </Flex>
    </Form>

    function renderContent(): ReactNode {
        return mobile
            ? renderMobileContent()
            : renderDesktopContent()
    }

    function renderMobileContent(): ReactNode {
        return <Flex align="start">
            {renderMobileCreator()}
            {renderMobileAgent()}
            {renderMobileStopper()}

            {renderMobileMomentAndDirection()}

            {renderMobileRequisitesSection()}
            {renderMobileRecipientSection()}
            {renderMobileProductsSection()}
            {renderMobileRouteSection()}
            {renderMobileCurrencyRateSection()}
            {renderMobileSharesAndExpensesSection()}
            {renderMobileProvidersSection()}
            {renderMobileDocumentsSection()}
            {renderMobileSignableDocumentsSection()}
            {renderMobileCommentsSection()}
        </Flex>
    }

    function renderDesktopContent() {
        return <div className={style.fields}>
            {renderDesktopCreator()}
            {renderDesktopAgent()}
            {renderDesktopStopper()}

            {renderDesktopDirection()}
            {renderDesktopMoment()}

            {renderDesktopRequisitesSection()}
            {renderDesktopRecipientSection()}
            {renderDesktopProductsSection()}
            {renderDesktopRouteSection()}
            {renderDesktopCurrencyRateSection()}
            {renderDesktopSharesAndExpensesSection()}
            {renderDesktopProvidersSection()}
            {renderDesktopDocumentsSection()}
            {renderDesktopSignableDocumentsSection()}
            {renderDesktopCommentsSection()}
        </div>
    }

    // - Creator

    function renderMobileCreator(): ReactNode {
        const { creatorId } = fields

        if (creatorId == null)
            return null

        const creator = innerUsersById.get(creatorId)

        return <Flex gap={MOBILE_FIELD_GAP}
                     align="start">
            {renderCreatorLabel()}
            {renderCreatorValue(creator)}
        </Flex>
    }

    function renderDesktopCreator(): ReactNode {
        const { creatorId } = fields

        if (creatorId == null)
            return null

        const creator = innerUsersById.get(creatorId)

        return <>
            <div className={style.column1Label}>
                {renderCreatorLabel()}
            </div>

            <div className={style.column2To4Value}>
                {renderCreatorValue(creator)}
            </div>
        </>
    }

    function renderCreatorLabel(): ReactNode {
        return <Label text={t("domain.transfers.labels.applicant")}/>
    }

    function renderCreatorValue(creator?: User): ReactNode {
        if (creator == null) {
            if (creatorLoadingError != null)
                <ErrorText
                    error={creatorLoadingError}
                    apiErrorMessageMapping={{
                        403: t("domain.users.messages.errors.accessDenied"),
                        404: t("domain.users.messages.errors.notFound"),
                    }}
                />

            return <LoadingIndicator/>
        }

        return <Output>
            <UserLink user={creator}
                      showCompany/>
        </Output>
    }

    // - Agent

    function renderMobileAgent(): ReactNode {
        const { creatorId } = fields

        if (creatorId == null)
            return null

        const creator = innerUsersById.get(creatorId)

        if (creator?.creatorId == null)
            return null

        const agent = innerUsersById.get(creator.creatorId)

        return <Flex gap={MOBILE_FIELD_GAP}
                     align="start">
            {renderAgentLabel()}
            {renderAgentValue(agent)}
        </Flex>
    }

    function renderDesktopAgent(): ReactNode {
        const { creatorId } = fields

        if (creatorId == null)
            return null

        const creator = innerUsersById.get(creatorId)

        if (creator?.creatorId == null)
            return null

        const agent = innerUsersById.get(creator.creatorId)

        return <>
            <div className={style.column1Label}>
                {renderAgentLabel()}
            </div>

            <div className={style.column2To4Value}>
                {renderAgentValue(agent)}
            </div>
        </>
    }

    function renderAgentLabel(): ReactNode {
        return <Label text={t("domain.users.labels.agent")}/>
    }

    function renderAgentValue(agent?: User): ReactNode {
        if (agent == null) {
            if (agentLoadingError != null)
                <ErrorText
                    error={agentLoadingError}
                    apiErrorMessageMapping={{
                        403: t("domain.users.messages.errors.accessDenied"),
                        404: t("domain.users.messages.errors.notFound"),
                    }}
                />

            return <LoadingIndicator/>
        }

        if (!agent.isAgent)
            return null

        return <Output>
            <UserLink user={agent}
                      showCompany/>
        </Output>
    }

    // - Stopper

    function renderMobileStopper(): ReactNode {
        const { stopperId } = fields

        if (stopperId == null)
            return null

        const stopper = innerUsersById.get(stopperId)

        return <Flex gap={MOBILE_FIELD_GAP}
                     align="start">
            {renderStopperLabel()}
            {renderStopperValue(stopper)}
        </Flex>
    }

    function renderDesktopStopper(): ReactNode {
        const { stopperId } = fields

        if (stopperId == null)
            return null

        const stopper = innerUsersById.get(stopperId)

        return <>
            <div className={style.column1Label}>
                {renderStopperLabel()}
            </div>

            <div className={style.column2To4Value}>
                {renderStopperValue(stopper)}
            </div>
        </>
    }

    function renderStopperLabel(): ReactNode {
        return <Label text={t("domain.transfers.labels.stopper")}/>
    }

    function renderStopperValue(stopper?: User): ReactNode {
        if (stopper== null) {
            if (stopperLoadingError != null)
                <ErrorText
                    error={stopperLoadingError}
                    apiErrorMessageMapping={{
                        403: t("domain.users.messages.errors.accessDenied"),
                        404: t("domain.users.messages.errors.notFound"),
                    }}
                />

            return <LoadingIndicator/>
        }

        return <Output>
            <UserLink user={stopper}
                      showCompany/>
        </Output>
    }

    // - Mobile moment and direction

    function renderMobileMomentAndDirection(): ReactNode {
        const { moment } = fields

        if (moment == null)
            return null

        return <div style={{
            display: "grid",
            gridTemplateColumns: "min-content auto",
            gridAutoRows: "min-content",
            gap: "16px",
        }}>
            <div className={style.column1Label}>
                {renderDirectionLabel()}
            </div>

            <div className={style.column2Value}>
                <Output height="32px">
                    {renderDirectionValue()}
                </Output>
            </div>

            {moment && <>
                <div className={style.column1Label}>
                    <Label text={t("domain.transfers.labels.date")}/>
                </div>

                <div className={style.column2Value}>
                    <DateOutput date={moment}/>
                </div>

                <div className={style.column1Label}>
                    <Label text={t("datetime.labels.time")}/>
                </div>

                <div className={style.column2Value}>
                    <TimeOutput date={moment}/>
                </div>
            </>}
        </div>
    }

    // - Direction

    function renderDesktopDirection(): ReactNode {
        return <>
            <div className={style.column1Label}>
                {renderDirectionLabel()}
            </div>

            <div className={style.direction}>
                {renderDirectionValue()}
            </div>
        </>
    }

    function renderDirectionLabel(): ReactNode {
        return <Label text={t("domain.transfers.labels.direction")}/>
    }

    function renderDirectionValue(): ReactNode {
        return <TransferDirectionRadio checked={fields.direction}
                                       exportHidden={fields.direction === "import"}
                                       importHidden={fields.direction === "export"}
                                       hideButton/>
    }

    // - Moment

    function renderDesktopMoment(): ReactNode {
        const { moment } = fields

        if (moment == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.date")}/>
            </div>

            <div className={style.column2Value}>
                <Flex direction="row"
                      width="fit-content">
                    <DateOutput date={moment}/>

                    <Label text={t("datetime.labels.time")}/>

                    <TimeOutput date={moment}/>
                </Flex>
            </div>
        </>
    }

    // - Requisites

    function renderMobileRequisitesSection(): ReactNode {
        if (fields.companyItn == null)
            return null

        return <>
            {renderRequisitesSubheader()}
            {renderMobileRequisitesSource()}
        </>
    }

    function renderDesktopRequisitesSection(): ReactNode {
        if (fields.companyItn == null)
            return null

        return <>
            {renderDesktopRequisitesSubheader()}
            {renderDesktopRequisitesSource()}
        </>
    }

    function renderDesktopRequisitesSubheader(): ReactNode {
        return <div className={style.subheader}>
            {renderRequisitesSubheader()}
        </div>
    }

    function renderRequisitesSubheader(): ReactNode {
        return <Subheader text={t("domain.transfers.subheaders.requisites")}/>
    }

    function renderMobileRequisitesSource(): ReactNode {
        return <Flex gap={MOBILE_FIELD_GAP}
                     align="start">
            {renderRequisitesSourceLabel()}
            {renderRequisitesSourceValue()}
        </Flex>
    }

    function renderDesktopRequisitesSource(): ReactNode {
        return <>
            <div className={style.column1Label}>
                {renderRequisitesSourceLabel()}
            </div>

            <div className={style.column2To4Value}>
                {renderRequisitesSourceValue()}
            </div>
        </>
    }

    function renderRequisitesSourceLabel(): ReactNode {
        return <Label text={t("domain.transfers.labels.requisitesSource")}/>
    }

    function renderRequisitesSourceValue(): ReactNode {
        if (companyLoadingError != null)
            <ErrorText error={companyLoadingError}/>

        if (company == null)
            return <LoadingIndicator/>

        return <Output>
            {getLang() === "ru"
                ? company.ruName ?? company.enName ?? t("misc.words.unknown")
                : company.enName ?? company.ruName ?? t("misc.words.unknown")
            }
        </Output>
    }

    // - Recipient

    function renderMobileRecipientSection(): ReactNode {
        return <>
            {renderRecipientSubheader()}
            {renderMobileCountry()}
            {renderMobileRecipient()}
            {renderMobileMoney()}
            {renderMobileRecipientRequisites()}
        </>
    }

    function renderDesktopRecipientSection(): ReactNode {
        return <>
            {renderDesktopRecipientSubheader()}
            {renderDesktopCountry()}
            {renderDesktopRecipient()}
            {renderDesktopMoney()}
            {renderDesktopRecipientRequisites()}
        </>
    }

    // -- Subheader

    function renderDesktopRecipientSubheader(): ReactNode {
        return <div className={style.subheader}>
            {renderRecipientSubheader()}
        </div>
    }

    function renderRecipientSubheader(): ReactNode {
        return <Subheader text={t("domain.transfers.subheaders.recipient")}/>
    }

    // -- Country

    function renderMobileCountry(): ReactNode {
        return <Output label={t("domain.transfers.labels.country")}>
            {fields.country}
        </Output>
    }

    function renderDesktopCountry(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.country")}/>
            </div>

            <div className={style.column2To4Value}>
                <Output>{fields.country}</Output>
            </div>
        </>
    }

    // -- Recipient

    function renderMobileRecipient(): ReactNode {
        return <>
            <Output label={t("domain.transfers.labels.recipient.name.full")}>
                {fields.recipient.name}
            </Output>

            {fields.recipient.itn != null &&
                <Output label={t("domain.transfers.labels.recipient.itn")}>
                    {fields.recipient.itn}
                </Output>
            }
        </>
    }

    function renderDesktopRecipient(): ReactNode {
        return <>
            {/* Name */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.name.full")}/>
            </div>

            <div className={style.column2Value}>
                <Output>{fields.recipient.name}</Output>
            </div>

            {/* ITN */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.itn")}/>
            </div>

            <div className={style.column4Value}>
                <Output>{fields.recipient.itn}</Output>
            </div>
        </>
    }

    // -- Money

    function renderMobileMoney(): ReactNode {
        return <MoneyOutput label={t("domain.transfers.labels.money")}
                            value={fields.money}/>
    }

    function renderDesktopMoney(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.money")}/>
            </div>

            <div className={style.column2To4Value}>
                <MoneyOutput value={fields.money}/>
            </div>
        </>
    }

    // -- Requisites

    function renderMobileRecipientRequisites(): ReactNode {
        return <>
            <Output label={t("domain.transfers.labels.recipient.bank.name")}>
                {fields.recipient.bank.name}
            </Output>

            <Output label={t("domain.transfers.labels.recipient.bank.swift")}>
                {fields.recipient.bank.swift}
            </Output>

            <Output label={t("domain.transfers.labels.recipient.bank.address")}>
                {fields.recipient.bank.address}
            </Output>

            {fields.recipient.iban != null &&
                <Output label={t("domain.transfers.labels.recipient.iban")}>
                    {fields.recipient.iban}
                </Output>
            }

            {fields.recipient.correspondentAccount != null &&
                <Output label={t("domain.transfers.labels.recipient.correspondentAccount.short")}>
                    {fields.recipient.correspondentAccount}
                </Output>
            }

            {fields.recipient.paymentAccount != null &&
                <Output label={t("domain.transfers.labels.recipient.paymentAccount.short")}>
                    {fields.recipient.paymentAccount}
                </Output>
            }
        </>
    }

    function renderDesktopRecipientRequisites(): ReactNode {
        return <>
            {/* Name */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.bank.name")}/>
            </div>

            <div className={style.column2Value}>
                <Output>{fields.recipient.bank.name}</Output>
            </div>

            {/* Swift */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.bank.swift")}/>
            </div>

            <div className={style.column4Value}>
                <Output>{fields.recipient.bank.swift}</Output>
            </div>

            {/* Address */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.bank.address")}/>
            </div>

            <div className={style.column2Value}>
                <Output>{fields.recipient.bank.address}</Output>
            </div>

            {/* IBAN */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.iban")}/>
            </div>

            <div className={style.column4Value}>
                <Output>{fields.recipient.iban}</Output>
            </div>

            {/* Correspondent account */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.correspondentAccount.short")}/>
            </div>

            <div className={style.column2Value}>
                <Output>{fields.recipient.correspondentAccount}</Output>
            </div>

            {/* Payment account */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.paymentAccount.short")}/>
            </div>

            <div className={style.column4Value}>
                <Output>{fields.recipient.paymentAccount}</Output>
            </div>
        </>
    }

    // - Products

    function renderMobileProductsSection(): ReactNode {
        if (fields.products.length === 0)
            return null

        return <>
            {renderProductsSubheader()}
            {renderMobileProducts()}
        </>
    }

    function renderDesktopProductsSection(): ReactNode {
        if (fields.products.length === 0)
            return null

        return <>
            {renderDesktopProductsSubheader()}
            {renderDesktopProducts()}
        </>
    }

    function renderDesktopProductsSubheader(): ReactNode {
        return <div className={style.subheader}>
            {renderProductsSubheader()}
        </div>
    }

    function renderProductsSubheader(): ReactNode {
        return <Subheader text={t("domain.transfers.subheaders.products")}/>
    }

    function renderMobileProducts(): ReactNode {
        return <ProductListEditor values={fields.products}
                                  mobile
                                  output/>
    }

    function renderDesktopProducts(): ReactNode {
        return <div className={style.column1To4Value}>
            <ProductListEditor values={fields.products}
                               output/>
        </div>
    }

    // - Route

    function renderMobileRouteSection(): ReactNode {
        if (fields.routePoints.length === 0)
            return null

        return <>
            {renderRouteSubheader()}
            {renderMobileRoute()}
        </>
    }

    function renderDesktopRouteSection(): ReactNode {
        if (fields.routePoints.length === 0)
            return null

        return <>
            {renderDesktopRouteSubheader()}
            {renderDesktopRoute()}
        </>
    }

    function renderDesktopRouteSubheader(): ReactNode {
        return <div className={style.subheader}>
            {renderRouteSubheader()}
        </div>
    }
    function renderRouteSubheader(): ReactNode {
        return <Subheader text={t("domain.transfers.subheaders.route")}/>
    }

    function renderMobileRoute(): ReactNode {
        return <RoutePointListEditor values={fields.routePoints}
                                     mobile
                                     output/>
    }

    function renderDesktopRoute(): ReactNode {
        return <div className={style.column1To4Value}>
            <RoutePointListEditor values={fields.routePoints}
                                  output/>
        </div>
    }

    // - Currency rate

    function renderMobileCurrencyRateSection() {
        if (fields.currencyRate == null)
            return

        return <>
            {renderCurrencyRateSubheader()}
            {renderCurrencyRate(true)}
        </>
    }

    function renderDesktopCurrencyRateSection() {
        if (fields.currencyRate == null)
            return

        return <>
            {renderDesktopCurrencyRateSubheader()}
            {renderCurrencyRate()}
        </>
    }

    function renderDesktopCurrencyRateSubheader(): ReactNode {
        return <div className={style.subheader}>
            {renderCurrencyRateSubheader()}
        </div>
    }

    function renderCurrencyRateSubheader(): ReactNode {
        return <Subheader text={t("domain.transfers.subheaders.currencyRate")}/>
    }

    function renderCurrencyRate(mobile?: boolean): ReactNode {
        const { currencyRate, money } = fields

        if (currencyRate == null)
            return null

        return mobile
            ? <>
                <Flex gap={MOBILE_FIELD_GAP}
                      align="start">
                    <Label text={t("domain.transfers.labels.currencyRate")}/>
                </Flex>

                <Flex gap={`calc(2 * ${MOBILE_FIELD_GAP})`}>
                    <MoneyOutput value={currencyRate.money}
                                precision={4}/>

                    <span style={{ transform: "rotate(90deg)" }}>
                        <Icon src={arrowsLeftRightIconUrl}
                              alt="Left and right arrows"
                              filter="brightness(0) invert(50%)"/>
                    </span>

                    <MoneyOutput value={new Money({ currency: money.currency, amount: 1 })}
                                 precision={4}/>
                </Flex>

                <DateOutput label={t("domain.currencyRates.labels.date")}
                            date={currencyRate.moment}/>

                <TimeOutput label={t("datetime.labels.time")}
                            date={currencyRate.moment}/>

                {currencyRate.evidence != null &&
                    <Flex gap={MOBILE_FIELD_GAP}
                          align="start">
                        <Label text={t("domain.currencyRates.labels.evidence")}/>

                        <DocumentUpload document={currencyRate.evidence}
                                        onChange={onCurrencyRateEvidenceChange}
                                        readonly/>
                    </Flex>
                }

                {currencyRate.evidenceLink &&
                    <Output label={t("domain.currencyRates.labels.evidenceLink")}>
                        <Link to={normalizeUri(currencyRate.evidenceLink)}
                              text={currencyRate.evidenceLink}/>
                    </Output>
                }
            </>

            : <>
                <div className={style.column1Label}>
                    <Label text={t("domain.transfers.labels.currencyRate")}/>
                </div>

                <div className={style.column2To4Value}>
                    <Flex direction="row"
                          width="fit-content">
                        <MoneyOutput value={currencyRate.money}
                                    precision={4}/>

                        <Icon src={arrowsLeftRightIconUrl}
                              alt="Left and right arrows"
                              filter="brightness(0) invert(50%)"/>

                        <MoneyOutput value={new Money({ currency: money.currency, amount: 1 })}
                                     precision={4}/>
                    </Flex>
                </div>

                <div className={style.column1Label}>
                    <Label text={t("domain.currencyRates.labels.date")}/>
                </div>

                <div className={style.column2Value}>
                    <Flex direction="row"
                          width="fit-content">
                        <DateOutput date={currencyRate.moment}/>

                        <Label text={t("datetime.labels.time")}/>

                        <TimeOutput date={currencyRate.moment}/>
                    </Flex>
                </div>

                {currencyRate.evidence != null && <>
                    <div className={style.column1Label}>
                        <Label text={t("domain.currencyRates.labels.evidence")}/>
                    </div>

                    <div className={style.column2To4Value}>
                        <DocumentUpload document={currencyRate.evidence}
                                        onChange={onCurrencyRateEvidenceChange}
                                        readonly/>
                    </div>
                </>}

                {currencyRate.evidenceLink && <>
                    <div className={style.column1Label}>
                        <Label text={t("domain.currencyRates.labels.evidenceLink")}/>
                    </div>

                    <div className={style.column2Value}>
                        <Link to={normalizeUri(currencyRate.evidenceLink)}
                              text={currencyRate.evidenceLink}/>
                    </div>
                </>}
            </>

        function onCurrencyRateEvidenceChange(newEvidence: UiDocument) {
            if (onChange == null || fields.currencyRate == null || !isFieldDocument(newEvidence))
                return

            const newFields = copyTransferFields(fields)

            assert(newFields.currencyRate != null)

            newFields.currencyRate.evidence = newEvidence

            onChange(newFields)
        }
    }

    // - Render shares and expenses section

    function renderMobileSharesAndExpensesSection(): ReactNode {
        if (fields.attorneyFee == null && fields.agentPercent == null && fields.expenses == null)
            return null

        return <>
            {renderSharesAndExpensesSubheader()}
            {renderMobileExpenses()}
            {renderMobileRealAttorneyPercent()}
            {renderMobileAttorneyFee()}
            {renderMobileAgentPercent()}
        </>
    }

    function renderDesktopSharesAndExpensesSection(): ReactNode {
        if (fields.attorneyFee == null && fields.agentPercent == null && fields.expenses == null)
            return null

        return <>
            {renderDesktopSharesAndExpensesSubheader()}
            {renderDesktopExpenses()}
            {renderDesktopRealAttorneyPercent()}
            {renderDesktopAttorneyFee()}
            {renderDesktopAgentPercent()}
        </>
    }

    function renderDesktopSharesAndExpensesSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.sharesAndExpenses")}/>
        </div>
    }

    function renderSharesAndExpensesSubheader(): ReactNode {
        return <Subheader text={t("domain.transfers.subheaders.sharesAndExpenses")}/>
    }

    function renderMobileExpenses(): ReactNode {
        const { expenses } = fields

        if (expenses == null)
            return null

        return <MoneyOutput label={t("domain.transfers.labels.expenses")}
                            value={expenses}/>
    }

    function renderDesktopExpenses(): ReactNode {
        const { expenses } = fields

        if (expenses == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.expenses")}/>
            </div>

            <div className={style.column2Value + " " + style.decimalOutput}>
                <MoneyOutput value={expenses}/>
            </div>
        </>
    }

    function renderMobileRealAttorneyPercent(): ReactNode {
        const { realAttorneyPercent } = fields

        if (realAttorneyPercent == null)
            return null

        return <DecimalOutput label={t("domain.transfers.labels.realAttorneyPercent")}
                              value={realAttorneyPercent}
                              precision={4}
                              right="%"/>
    }

    function renderDesktopRealAttorneyPercent(): ReactNode {
        const { realAttorneyPercent } = fields

        if (realAttorneyPercent == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.realAttorneyPercent")}/>
            </div>

            <div className={style.column2Value + " " + style.decimalOutput}>
                <DecimalOutput value={realAttorneyPercent}
                               precision={4}
                               right="%"/>
            </div>
        </>
    }

    function renderMobileAttorneyFee(): ReactNode {
        const { attorneyFee } = fields

        if (attorneyFee == null)
            return null

        return <DecimalOutput label={t("domain.transfers.labels.attorneyFee")}
                              wrapLabel

                              value={attorneyFee}
                              right="₽"/>
    }

    function renderDesktopAttorneyFee(): ReactNode {
        const { attorneyFee } = fields

        if (attorneyFee == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.attorneyFee")}
                       wrap/>
            </div>

            <div className={style.column2Value + " " + style.decimalOutput}>
                <DecimalOutput value={attorneyFee}
                               right="₽"/>
            </div>
        </>
    }

    function renderMobileAgentPercent(): ReactNode {
        const { agentPercent } = fields

        if (agentPercent == null)
            return null

        return <DecimalOutput label={t("domain.transfers.labels.agentPercent")}
                              value={agentPercent}
                              precision={4}
                              right="%"/>
    }

    function renderDesktopAgentPercent(): ReactNode {
        const { agentPercent } = fields

        if (agentPercent == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.agentPercent")}/>
            </div>

            <div className={style.column2Value + " " + style.decimalOutput}>
                <DecimalOutput value={agentPercent}
                               precision={4}
                               right="%"/>
            </div>
        </>
    }

    // - Providers

    function renderMobileProvidersSection(): ReactNode {
        if (!fields.providers?.length)
            return null

        return <>
            {renderProvidersSubheader()}
            {renderProviders(true)}
        </>
    }

    function renderDesktopProvidersSection(): ReactNode {
        if (!fields.providers?.length)
            return null

        return <>
            {renderDesktopProvidersSubheader()}
            {renderProviders()}
        </>
    }

    function renderDesktopProvidersSubheader(): ReactNode {
        return <div className={style.subheader}>
            {renderProvidersSubheader()}
        </div>
    }

    function renderProvidersSubheader(): ReactNode {
        return <Subheader text={t("domain.transfers.subheaders.providers")}/>
    }

    function renderProviders(mobile?: boolean): ReactNode {
        const { providers } = fields

        if (providers == null)
            return

        return mobile
            ? renderValue()

            : <div className={style.column1To4Value}>
                {renderValue()}
            </div>

        function renderValue() {
            return <TransferProviderListEditor values={providers!}

                                               onUsersChange={onUsersChange}
                                               users={innerUsersById}

                                               onProvidersChange={onProvidersChange}
                                               providers={innerProvidersById}

                                               allowEmpty
                                               output
                                               mobile={mobile}/>
        }

        function onUsersChange(newUsers: User[]) {
            setInnerUsersById(oldUsersById => {
                const newUsersById = new Map(oldUsersById)

                for (const user of newUsers)
                    newUsersById.set(user.id, user)

                return newUsersById
            })
        }

        function onProvidersChange(newProviders: Provider[]) {
            setInnerProvidersById(oldProvidersById => {
                const newProvidersById = new Map(oldProvidersById)

                for (const provider of newProviders)
                    newProvidersById.set(provider.id, provider)

                return newProvidersById
            })
        }
    }

    // - Documents

    function renderMobileDocumentsSection(): ReactNode {
        return <>
            {renderDocumentsSubheader()}
            {renderInvoice(true)}
            {renderContract(true)}
            {renderDocuments(true)}
        </>
    }

    function renderDesktopDocumentsSection(): ReactNode {
        return <>
            {renderDesktopDocumentsSubheader()}
            {renderInvoice()}
            {renderContract()}
            {renderDocuments()}
        </>
    }

    function renderDesktopDocumentsSubheader(): ReactNode {
        return <div className={style.subheader}>
            {renderDocumentsSubheader()}
        </div>
    }

    function renderDocumentsSubheader(): ReactNode {
        return <Subheader text={t("domain.transfers.subheaders.documents")}/>
    }

    function renderInvoice(mobile?: boolean): ReactNode {
        return renderSpecialDocument("invoice", mobile)
    }

    function renderContract(mobile?: boolean): ReactNode {
        return renderSpecialDocument("contract", mobile)
    }

    function renderSpecialDocument(type: "invoice" | "contract", mobile?: boolean): ReactNode {
        return mobile
            ? <Group>
                <Flex>
                    <Output label={t(`domain.transfers.labels.${type}.number`)}>
                        {fields[type].number}
                    </Output>

                    <DateOutput label={t(`domain.transfers.labels.${type}.date`)}
                                date={fields[type].date}/>

                    <Flex gap={MOBILE_FIELD_GAP}
                          align="start">
                        <Label text={t(`domain.transfers.labels.${type}.document`)}/>

                        <DocumentUpload onChange={onDocumentChange}
                                        document={fields[type].document!}
                                        readonly/>
                    </Flex>
                </Flex>
            </Group>

            : <>
                {/* Date and number */}

                <div className={style.column1Label}>
                    <Label text={t(`domain.transfers.labels.${type}.number`)}/>
                </div>

                <div className={style.column2To4Value}>
                    <Flex direction="row"
                          width="fit-content">
                        <Output>{fields[type].number}</Output>

                        <Label text={t(`domain.transfers.labels.${type}.date`)}/>

                        <DateOutput date={fields[type].date}/>
                    </Flex>
                </div>

                {/* Document */}

                {fields[type].document != null && <>
                    <div className={style.column1Label}>
                        <Label text={t(`domain.transfers.labels.${type}.document`)}/>
                    </div>

                    <div className={style.column2To4Value}>
                        <DocumentUpload onChange={onDocumentChange}
                                        document={fields[type].document!}
                                        readonly/>
                    </div>
                </>}
            </>

        function onDocumentChange(newDocument: UiDocument) {
            if (onChange == null || !isFieldDocument(newDocument))
                return

            const newFields = copyTransferFields(fields)

            newFields[type].document = newDocument

            onChange(newFields)
        }
    }

    function renderDocuments(mobile?: boolean): ReactNode {
        const { documents } = fields

        if (documents.length === 0)
            return null

        return mobile
            ? <Flex gap={MOBILE_FIELD_GAP}
                    align="start">
                <Label text={t("domain.transfers.labels.documents")}/>

                <DocumentListUpload onChange={onDocumentsChange}
                                    documents={documents}
                                    readonly/>
            </Flex>

            : <>
                <div className={style.column1Label}>
                    <Label text={t("domain.transfers.labels.documents")}/>
                </div>

                <div className={style.column2To4Value}>
                    <DocumentListUpload onChange={onDocumentsChange}
                                        documents={documents}
                                        readonly/>
                </div>
            </>

        function onDocumentsChange(newDocuments: UiDocument[]) {
            if (onChange == null)
                return

            const newFields = copyTransferFields(fields)

            newFields.documents = newDocuments.filter(isFieldDocument)

            onChange(newFields)
        }
    }

    // - SignableDocuments

    function renderMobileSignableDocumentsSection(): ReactNode {
        if (fields.signableDocuments.length === 0)
            return null

        return <>
            {renderSignableDocumentsSubheader()}
            {renderSignableDocuments(true)}
        </>
    }

    function renderDesktopSignableDocumentsSection(): ReactNode {
        if (fields.signableDocuments.length === 0)
            return null

        return <>
            {renderDesktopSignableDocumentsSubheader()}
            {renderSignableDocuments()}
        </>
    }

    function renderDesktopSignableDocumentsSubheader(): ReactNode {
        return <div className={style.subheader}>
            {renderSignableDocumentsSubheader()}
        </div>
    }

    function renderSignableDocumentsSubheader(): ReactNode {
        return <Subheader text={t("domain.transfers.subheaders.signableDocuments")}/>
    }

    function renderSignableDocuments(mobile?: boolean): ReactNode {
        return mobile
            ? renderValue(true)

            : <div className={style.column1To4Value}>
                {renderValue(false)}
            </div>

        function renderValue(mobile?: boolean): ReactNode {
            return <SignableDocumentListEditor onChange={onSignableDocumentsChange}
                                               values={fields.signableDocuments}

                                               width={mobile ? "100%" : "fit-content"}

                                               mobile={mobile}
                                               allowEmpty
                                               output/>
        }

        function onSignableDocumentsChange(newSignableDocuments: SignableDocumentFields[]) {
            if (onChange == null)
                return

            const newFields = copyTransferFields(fields)

            newFields.signableDocuments = newSignableDocuments

            onChange(newFields)
        }
    }

    // - Comments

    function renderMobileCommentsSection(): ReactNode {
        if (noComments())
            return null

        return <>
            {renderCommentsSubheader()}
            {renderMobileComment()}
            {renderMobileAdminComment()}
            {renderMobileLawyerComment()}
            {renderMobileAccountantComment()}
        </>
    }


    function renderDesktopCommentsSection(): ReactNode {
        if (noComments())
            return null

        return <>
            {renderDesktopCommentsSubheader()}
            {renderDesktopComment()}
            {renderDesktopAdminComment()}
            {renderDesktopLawyerComment()}
            {renderDesktopAccountantComment()}
        </>
    }

    function noComments(): boolean {
        return isNullOrBlank(fields.comment)
            && isNullOrBlank(fields.adminComment)
            && isNullOrBlank(fields.lawyerComment)
            && isNullOrBlank(fields.accountantComment)
    }

    function renderDesktopCommentsSubheader(): ReactNode {
        return <div className={style.subheader}>
            {renderCommentsSubheader()}
        </div>
    }

    function renderCommentsSubheader(): ReactNode {
        return <Subheader text={t("domain.transfers.subheaders.comments")}/>
    }

    function renderMobileComment(): ReactNode {
        const { comment } = fields

        if (isNullOrBlank(comment))
            return null

        return <Output label={t("domain.transfers.labels.comment")}
                       wordBreak="break-all"
                       resize="vertical"
                       wrapLabel>
            {comment}
        </Output>
    }

    function renderDesktopComment(): ReactNode {
        const { comment } = fields

        if (isNullOrBlank(comment))
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.comment")}/>
            </div>

            <div className={style.column2To4Value}>
                <Output wordBreak="break-all"
                        resize="vertical">
                    {comment}
                </Output>
            </div>
        </>
    }

    function renderMobileAdminComment(): ReactNode {
        const { adminComment } = fields

        if (isNullOrBlank(adminComment))
            return null

        return <Output label={t("domain.transfers.labels.adminComment")}
                       wordBreak="break-all"
                       resize="vertical"
                       wrapLabel>
            {adminComment}
        </Output>
    }

    function renderDesktopAdminComment(): ReactNode {
        const { adminComment } = fields

        if (isNullOrBlank(adminComment))
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.adminComment")}
                       wrap/>
            </div>

            <div className={style.column2To4Value}>
                <Output wordBreak="break-all"
                        resize="vertical">
                    {adminComment}
                </Output>
            </div>
        </>
    }

    function renderMobileLawyerComment(): ReactNode {
        const { lawyerComment } = fields

        if (isNullOrBlank(lawyerComment))
            return null

        return <Output label={t("domain.transfers.labels.lawyerComment")}
                       wordBreak="break-all"
                       resize="vertical"
                       wrapLabel>
            {lawyerComment}
        </Output>
    }

    function renderDesktopLawyerComment(): ReactNode {
        const { lawyerComment } = fields

        if (isNullOrBlank(lawyerComment))
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.lawyerComment")}
                       wrap/>
            </div>

            <div className={style.column2To4Value}>
                <Output wordBreak="break-all"
                        resize="vertical">
                    {lawyerComment}
                </Output>
            </div>
        </>
    }

    function renderMobileAccountantComment(): ReactNode {
        const { accountantComment } = fields

        if (isNullOrBlank(accountantComment))
            return null

        return <Output label={t("domain.transfers.labels.accountantComment")}
                       wordBreak="break-all"
                       resize="vertical"
                       wrapLabel>
                {accountantComment}
            </Output>
    }

    function renderDesktopAccountantComment(): ReactNode {
        const { accountantComment } = fields

        if (isNullOrBlank(accountantComment))
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.accountantComment")}
                       wrap/>
            </div>

            <div className={style.column2To4Value}>
                <Output wordBreak="break-all"
                        resize="vertical">
                    {accountantComment}
                </Output>
            </div>
        </>
    }

    // - Controls

    function renderControls(): ReactNode {
        return <FormControls buttons={buttons}

                             disabled={disabled}
                             loading={loading}
                             error={error}/>
    }
})

TransferViewForm.displayName = "TransferViewForm"
