import { map } from "my-util/iterable"
import { Nullish } from "my-util/type"

export interface SearchParamsJoiningOptions extends Nullish<{
    encode: boolean
}> {}

export function joinObjectSearchParams(
    params: { [key: string]: unknown },
    options: Readonly<SearchParamsJoiningOptions> = {},
): string {
    return joinSearchParams(
        Object.entries(params)
            .map(([key, value]) => [key, value?.toString()])
            .filter(([, value]) => value != null) as [string, string][],

        options,
    )
}

export function joinSearchParams(
    params: Iterable<readonly [string, string]>,
    { encode }: Readonly<SearchParamsJoiningOptions> = {},
): string {
    return map(
        params,

        encode
            ? ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            : ([key, value]) => `${key}=${value}`,
    ).join("&")
}
