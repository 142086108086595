import { CacheService } from "./Cache"

export namespace DefaultCacheService {
    export type Get<T> = (path: string) => Promise<T>
}

export class DefaultCacheService<T> implements CacheService<T> {
    // Fields

    private _getImpl: (path: string) => Promise<T>
    private _entries: Map<string, T>

    // Constructor

    constructor(getImpl: DefaultCacheService.Get<T>) {
        this._getImpl = getImpl
        this._entries = new Map()
    }

    // Iterators

    [Symbol.iterator](): Iterator<[string, T], any, any> {
        return this.entries()
    }

    entries(): Iterator<[string, T], any, any> {
        return this._entries.entries()
    }

    paths(): Iterator<string> {
        return this._entries.keys()
    }

    values(): Iterator<T> {
        return this._entries.values()
    }

    // Size

    size(): number {
        return this._entries.size
    }

    // Has

    has(path: string): boolean {
        return this._entries.has(path)
    }

    // Get

    async get(path: string): Promise<T> {
        const old = this._entries.get(path)

        if (old != null)
            return old

        return this._getAndCache(path)
    }

    reget(path: string): Promise<T> {
        this.delete(path)
        return this._getAndCache(path)
    }

    private async _getAndCache(path: string): Promise<T> {
        const value = await this._getImpl(path)

        this._entries.set(path, value)

        return value
    }

    // Delete

    delete(path: string): boolean {
        return this._entries.delete(path)
    }
}
