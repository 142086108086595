import { useCallback, useState } from "react"

export function useRerender(): () => void {
    const [value, setValue] = useState(Number.MIN_SAFE_INTEGER)

    const rerender = useCallback(
        () => setValue(oldValue => {
            return oldValue >= Number.MAX_SAFE_INTEGER
                ? Number.MIN_SAFE_INTEGER
                : oldValue + 1
        }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value],
    )

    return rerender
}
