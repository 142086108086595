import { ForwardedRef, forwardRef } from "react"
import { DeepReadonly } from "my-util"
import { Button as ButtonComponent } from "ui/ui/input"
import { ErrorText } from "ui/ui/output"
import { Flex } from "ui/ui/layout"

const MOBILE_BUTTONS_WIDTH = "calc(50% - 16px)"

export namespace FormControls {
    export interface Props {
        buttons?: (Button | undefined | null)[]

        loading?: boolean
        disabled?: boolean

        width?: string

        error?: unknown
        apiErrorMessageMapping?: ErrorText.ApiErrorMessageMapping
    }

    export interface Button extends ButtonComponent.Props {
        position: Button.Position
        hidden?: boolean
    }

    export namespace Button {
        export type Position =
            | "left"
            | "right"
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormControls = forwardRef((
    {
        buttons,
        loading, disabled,
        width,
        error, apiErrorMessageMapping,
    }: DeepReadonly<FormControls.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <Flex width={width}
                 ref={ref}>
        <ErrorText apiErrorMessageMapping={apiErrorMessageMapping}
                    error={error}/>

        <Flex justify="space-between"
                direction="row">
            {renderButtons("left", MOBILE_BUTTONS_WIDTH)}
            {renderButtons("right", MOBILE_BUTTONS_WIDTH)}
        </Flex>
    </Flex>

    function renderButtons(position: FormControls.Button.Position, width?: string) {
        const filteredButtons = buttons?.filter(button =>
            button != null &&
            !button.hidden &&
            button.position === position
        ) ?? [] as DeepReadonly<FormControls.Button[]>

        return <Flex width={width}
                     justify={position === "left" ? "start" : "end"}
                     direction="row"
                     gap="8px"
                     wrap>
            {
                filteredButtons.map((button, i) =>
                    <ButtonComponent width="fit-content"
                                     loading={loading}
                                     disabled={disabled}
                                     {...button}
                                     key={i}/>
                )
            }
        </Flex>
    }
})

FormControls.displayName = "FormControls"
