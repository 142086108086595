import { z } from "zod"
import { LocalDateSchema, UuidSchema } from "my-util"

export const LegalDocumentMessageSchema = z.object({
    number: z.string(),
    date: LocalDateSchema,
    documentId: UuidSchema.nullish(),
})

export type LegalDocumentMessage = z.infer<typeof LegalDocumentMessageSchema>
