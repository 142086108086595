import { AbstractModelObject } from "model/AbstractModelObject"
import { collapseWhiteSpaceToNull, Copyable, DeepReadonly, Nullish } from "my-util"

export namespace Subsystem {
    export interface OptionsBase extends Nullish<{
        key: string
        name: string
        address: string
    }>{}

    export interface CreationOptions extends
        AbstractModelObject.CreationOptions,
        OptionsBase
    {}

    export interface CopyOptions extends
        AbstractModelObject.CopyOptions,
        OptionsBase
    {}
}

export class Subsystem extends AbstractModelObject<Subsystem.CopyOptions> {
    // Fields

    readonly key: string
    readonly name: string | null
    readonly address: string | null

    // Constructor

    constructor(options: DeepReadonly<Subsystem.CreationOptions> = {}) {
        super(options)

        this.key = options.key ?? ""
        this.name = collapseWhiteSpaceToNull(options.name ?? "")
        this.address = collapseWhiteSpaceToNull(options.address ?? "")
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<Subsystem.CopyOptions> = {}
    ): Copyable<Subsystem.CopyOptions> {
        return new Subsystem({
            key: options.key ?? this.key,

            name: "name" in options
                ? options.name
                : this.name,

            address: "address" in options
                ? options.address
                : this.address,

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
