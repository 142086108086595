import { ForwardedRef, forwardRef, Fragment, useContext } from "react"
import { useTranslation } from "react-i18next"
import { editIconUrl, trashCanIconUrl } from "images"
import { Subsystem } from "model"
import { createUri, DeepReadonly, map } from "my-util"
import { UserContext } from "ui/context"
import { Button, Copy, Flex, Input, Link, Output } from "ui/ui"
import style from "./style.module.css"

export namespace SubsystemTable {
    export interface Props {
        onDelete?: (provider: Subsystem) => void
        onEdit?: (provider: Subsystem) => void

        subsystems: Iterable<Subsystem>

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubsystemTable = forwardRef((
    {
        onDelete, onEdit,
        subsystems,
        width,
    }: DeepReadonly<SubsystemTable.Props>,
    ref: ForwardedRef<HTMLTableElement>,
) => {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    return <table className={style.table}
                  style={{ width }}
                  ref={ref}>
        <tbody>
            {map(subsystems, subsystem =>
                <Fragment key={subsystem.id}>
                    <tr>
                        <th className={subsystem.name != null ? style.name : style.unnamed}
                            colSpan={2}>
                            <Flex direction="row"
                                  justify="space-between">
                                {subsystem.name ?? t("domain.subsystems.messages.no.name")}

                                {subsystem.address != null && localUser != null &&
                                    <Link
                                        text={t("misc.actions.login")}
                                        to={createUri({
                                            host: subsystem.address,
                                            subpaths: ["/login", localUser.id],
                                            schema: "https",
                                        })}
                                    />
                                }
                            </Flex>
                        </th>

                        <td>
                            <Button onClick={() => onEdit?.(subsystem)}

                                    iconSrc={editIconUrl}
                                    iconAlt="Edit icon"

                                    width="32px"
                                    height="32px"

                                    buttonStyle="text"/>
                        </td>
                    </tr>

                    <tr>
                        <th className={style.background}>
                            {t("domain.subsystems.labels.address")}
                        </th>

                        <td className={style.background}>
                            <Flex direction="row"
                                  gap="8px">
                                <Output minHeight="28px"
                                        valueHeight="28px"
                                        height="28px">
                                    <span style={{
                                        position: "relative",
                                        fontSize: "14px",
                                        top: "-1px",
                                    }}>
                                        {subsystem.address != null
                                            ? <Link to={subsystem.address}/>

                                            : <span className={style.missingValue}>
                                                {t("domain.subsystems.messages.no.address")}
                                            </span>
                                        }
                                    </span>
                                </Output>

                                <Copy value={subsystem.address ?? ""}/>
                            </Flex>
                        </td>

                        <td>
                            <Button onClick={() => onDelete?.(subsystem)}

                                    iconSrc={trashCanIconUrl}
                                    iconAlt="Trash can icon"

                                    width="32px"
                                    height="32px"

                                    buttonStyle="text"

                                    critical/>
                        </td>
                    </tr>

                    <tr>
                        <th className={style.background}>
                            {t("domain.subsystems.labels.key")}
                        </th>

                        <td className={style.background}>
                            <Flex direction="row"
                                  gap="8px">
                                <div className={style.input}>
                                    <Input value={subsystem.key}
                                           type="password"
                                           readonly
                                           height="28px"/>
                                </div>

                                <Copy value={subsystem.key}/>
                            </Flex>
                        </td>

                        <td/>
                    </tr>

                    <tr className={style.gap}/>
                </Fragment>
            )}
        </tbody>
    </table>
})


SubsystemTable.displayName = "SubsystemTable"
