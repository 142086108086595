import { JsonPatch, SubsystemRequest } from "api/requests"
import { SubsystemResponseSchema } from "api/responses"
import { Subsystem } from "model"
import { DeepReadonly, joinSubpaths } from "my-util"
import { countEntities, del, get, isEntityExists, patchEntity, post, put } from "../util"

export const SUBSYSTEMS_SUBPATH = "/subsystems"

// Count

export async function countSubsystems(signal?: AbortSignal | null): Promise<number> {
    return await countEntities(joinSubpaths([SUBSYSTEMS_SUBPATH, "/count"]), signal)
}

// Exists

export async function isSubsystemExistById(id: string, signal?: AbortSignal | null): Promise<boolean> {
    return await isEntityExists(joinSubpaths([SUBSYSTEMS_SUBPATH, id, "/exists"]), signal)
}

// Get many

export async function getAllSubsystems(signal?: AbortSignal | null): Promise<Subsystem[]> {
    return (await get({
        subpath: SUBSYSTEMS_SUBPATH,
        schema: SubsystemResponseSchema.array(),
        signal,
    })).map(response => new Subsystem(response))
}

// Get one

export async function getSubsystemById(id: string, signal?: AbortSignal | null): Promise<Subsystem> {
    return new Subsystem(await get({
        subpath: joinSubpaths([SUBSYSTEMS_SUBPATH, id]),
        schema: SubsystemResponseSchema,
        signal,
    }))
}

// Delete many

export async function deleteAllSubsystems(signal?: AbortSignal | null) {
    return del({
        subpath: SUBSYSTEMS_SUBPATH,
        signal,
    })
}

// Delete one

export async function deleteSubsystemById(id: string, signal?: AbortSignal | null) {
    return del({
        subpath: joinSubpaths([SUBSYSTEMS_SUBPATH, id]),
        signal,
    })
}

// Patch

export async function patchSubsystemById(
    id: string,
    patches: DeepReadonly<JsonPatch[]>,
    signal?: AbortSignal | null,
): Promise<Subsystem> {
    return new Subsystem(await patchEntity({
        subpath: joinSubpaths([SUBSYSTEMS_SUBPATH, id]),
        schema: SubsystemResponseSchema,
        patches,
        signal,
    }))
}

// Put

export async function putSubsystemById(
    id: string,
    request: DeepReadonly<SubsystemRequest>,
    signal?: AbortSignal | null,
): Promise<Subsystem> {
    return new Subsystem(await put({
        subpath: joinSubpaths([SUBSYSTEMS_SUBPATH, id]),
        schema: SubsystemResponseSchema,
        body: request,
        signal,
    }))
}

// Create

export async function createSubsystem(
    request: DeepReadonly<SubsystemRequest>,
    signal?: AbortSignal | null,
): Promise<Subsystem> {
    return new Subsystem(await post({
        subpath: SUBSYSTEMS_SUBPATH,
        schema: SubsystemResponseSchema,
        body: request,
        signal,
    }))
}
