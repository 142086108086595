import { ForwardedRef, forwardRef, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Subsystem } from "model"
import { DeepReadonly, generateRandomUuid } from "my-util"
import { validatePassword, validateShortText } from "validation"
import { ActionModal, Button, Flex, Input } from "ui/ui"

export namespace SubsystemActionModal {
    export interface Props extends Omit<ActionModal.Props, "onYes" | "yesDisabled"> {
        onYes: (provider: Subsystem) => (Promise<void> | void)

        subsystem?: Subsystem
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubsystemActionModal = forwardRef((
    props: DeepReadonly<SubsystemActionModal.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const { onYes, subsystem } = props

    const [t] = useTranslation()

    // State

    const [loading, setLoading] = useState(false)

    const [name, setName] = useState(subsystem?.name ?? "")
    const [touchedName, setTouchedName] = useState(false)
    const nameInvalid = useMemo(() => validateShortText(name) != null, [name])

    const [address, setAddress] = useState(subsystem?.address ?? "")
    const [touchedAddress, setTouchedAddress] = useState(false)
    const addressInvalid = useMemo(() => validateShortText(address) != null, [address])

    const [key, setKey] = useState(subsystem?.key ?? "")
    const [touchedKey, setTouchedKey] = useState(false)
    const keyInvalid = useMemo(() => validatePassword(key) != null, [key])

    const saveDisabled =
        keyInvalid ||
        nameInvalid ||
        addressInvalid

    // Render

    return <ActionModal { ...props }
                        onYes={onInnerYes}
                        yesDisabled={saveDisabled}
                        ref={ref}>
        <Flex>
            <Input onChange={setName}
                   value={name}

                   onFocus={() => setTouchedName(true)}

                   invalid={touchedName && nameInvalid}
                   loading={loading}

                   autoComplete="off"

                   label={t("domain.subsystems.labels.name")}
                   placeholder={t("domain.subsystems.placeholders.name")}/>

            <Input onChange={setAddress}
                   value={address}

                   onFocus={() => setTouchedAddress(true)}

                   invalid={touchedAddress && addressInvalid}
                   loading={loading}

                   autoComplete="off"

                   label={t("domain.subsystems.labels.address")}
                   placeholder={t("domain.subsystems.placeholders.address")}/>

            <Input onChange={setKey}
                   value={key}

                   onFocus={() => setTouchedKey(true)}

                   invalid={touchedKey && keyInvalid}
                   loading={loading}

                   autoComplete="off"
                   type="password"

                   label={t("domain.subsystems.labels.key")}
                   placeholder={t("domain.subsystems.placeholders.key")}/>

            <Button onClick={() => setKey(generateRandomUuid())}
                    text={t("domain.subsystems.actions.generateKey")}
                    buttonStyle="text"/>
        </Flex>
    </ActionModal>

    // Events

    async function onInnerYes() {
        setLoading(true)

        try {
            const newSubsystem =
                subsystem?.copy({ key, name, address }) ??
                new Subsystem({ key, name, address })

            await onYes(newSubsystem)
        } finally {
            setLoading(false)
        }
    }
})

SubsystemActionModal.displayName = "SubsystemActionModal"
