import { CacheService } from "./Cache"

export namespace NullCacheService {
    export type Get<T> = (path: string) => Promise<T>
}

export class NullCacheService<T> implements CacheService<T> {
    // Fields

    private _getImpl: (path: string) => Promise<T>

    // Constructor

    constructor(getImpl: NullCacheService.Get<T>) {
        this._getImpl = getImpl
    }

    // Iterators

    [Symbol.iterator](): Iterator<[string, T], any, any> {
        return this.entries()
    }

    * entries(): Iterator<[string, T], any, any> {}

    * paths(): Iterator<string> {}

    * values(): Iterator<T> {}

    // Size

    size(): number {
        return 0
    }

    // Has

    has(path: string): boolean {
        return false
    }

    // Get

    get(path: string): Promise<T> {
        return this.reget(path)
    }

    reget(path: string): Promise<T> {
        return this._getImpl(path)
    }

    // Delete

    delete(path: string): boolean {
        return false
    }
}
