import { useEffect, useImperativeHandle, useCallback,
         ForwardedRef, ReactNode, useLayoutEffect, useRef } from "react"

import { applyToAllElementsToWindow, domRectContains, forwardRefAndSetProperties } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Hovering } from "ui/ui/appearance"
import { Padding } from "ui/ui/layout"
import style from "./style.module.css"

export namespace Tooltip {
    export interface Props {
        onShow?: (shown: boolean) => void
        show?: boolean

        children?: ReactNode

        gap?: number
        windowPadding?: number
        position?: Position
    }

    export interface Consts {
        DEFAULT_GAP: number
        DEFAULT_WINDOW_PADDING: number
        DEFAULT_POSITION: Position
    }

    export type Position =
        | "top"
        | "bottom"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Tooltip = forwardRefAndSetProperties(
    {
        DEFAULT_GAP: 8 as number,
        DEFAULT_WINDOW_PADDING: 16 as number,
        DEFAULT_POSITION: "top" as Tooltip.Position,
    } as const,

    (
        {
            onShow, show,
            children,
            gap, windowPadding, position,
        }: Readonly<Tooltip.Props>,

        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const innerGap = gap ?? Tooltip.DEFAULT_GAP
        const innerWindowPadding = windowPadding ?? Tooltip.DEFAULT_WINDOW_PADDING
        const innerPosition = position ?? Tooltip.DEFAULT_POSITION

        // Refs

        const containerRef = useRef<HTMLDivElement>(null)
        const contentRef = useRef<HTMLDivElement>(null)

        useImperativeHandle(ref, () => containerRef.current!, [])

        // State

        const [innerShow, setInnerShow] = useStateWithDeps(() => show ?? false, [show])

        const updatePositionMemo = useCallback(
            updatePosition,
            [innerGap, innerPosition, innerWindowPadding],
        )

        // Effects

        // - State propagation

        useEffect(
            () => { onShow?.(innerShow) },
            [onShow, innerShow],
        )

        // - Position update on show property toggling

        useEffect(() => {
            if (innerShow && show != null)
                updatePositionMemo()
        }, [innerShow, show, updatePositionMemo])

        // - Mouse move event handling

        useEffect(() => {
            if (show != null)
                return

            window.addEventListener("mousemove", handleMouseMove)

            return () => window.removeEventListener("mousemove", handleMouseMove)

            function handleMouseMove(event: MouseEvent) {
                const container = containerRef.current

                if (container == null)
                    return

                const { x, y } = event

                const containerRect = container.getBoundingClientRect()

                const newVisible = domRectContains(containerRect, x, y) && (
                    event.target instanceof Node
                        ? getSuperContainer()?.contains(event.target) ||
                        event.target.contains(container)

                        : true
                )

                if (newVisible)
                    onMouseEnter()
                else
                    onMouseLeave()
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [show, updatePositionMemo])

        // - Resize handling

        useLayoutEffect(() => {
            const content = contentRef.current

            if (content == null)
                return

            const observer = new ResizeObserver(updatePositionMemo)

            observer.observe(content)

            return () => observer.disconnect()
        }, [updatePositionMemo])

        // - Scroll handling

        useEffect(() => {
            const container = containerRef.current

            if (container == null)
                return

            applyToAllElementsToWindow(
                container,
                element => element.addEventListener("scroll", updatePositionMemo),
            )

            return () => applyToAllElementsToWindow(
                container,
                element => element.removeEventListener("scroll", updatePositionMemo),
            )
        }, [updatePositionMemo])

        // Render

        return <div className={style.container}
                    ref={containerRef}>
            <div style={{ display: innerShow ? "block" : "none" }}
                 className={style.content}
                 ref={contentRef}>
                <Hovering>
                    <Padding padding="8px">
                        {children}
                    </Padding>
                </Hovering>
            </div>
        </div>

        // Events

        function onMouseEnter() {
            setInnerShow(true)
            updatePositionMemo()
        }

        function onMouseLeave() {
            setInnerShow(false)
        }

        // Util

        function getSuperContainer(): HTMLElement | null {
            const container = containerRef.current

            if (container == null)
                return null

            let superContainer = container.parentElement

            while (superContainer != null)  {
                const computedStyles = getComputedStyle(superContainer)

                if (computedStyles.position === "relative")
                    return superContainer

                superContainer = superContainer.parentElement
            }

            return null
        }

        function updatePosition() {
            // Elements getting

            const container = containerRef.current

            if (container == null)
                return

            const content = contentRef.current

            if (content == null)
                return

            // Calculation

            const containerRect = container.getBoundingClientRect()
            const contentRect = content.getBoundingClientRect()

            // - Left

            let newContentLeft = containerRect.x + .5 * (containerRect.width - contentRect.width)

            if (newContentLeft < innerWindowPadding)
                newContentLeft = innerWindowPadding

            // - Top

            let newContentTop = innerPosition === "top"
                ? containerRect.top - contentRect.height - innerGap
                : containerRect.bottom + innerGap

            if (newContentTop < innerWindowPadding)
                newContentTop = innerWindowPadding

            // Update

            content.style.left = `${newContentLeft}px`
            content.style.top = `${newContentTop}px`
        }
    },
)

Tooltip.displayName = "Tooltip"
