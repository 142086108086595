import { z } from "zod"

export const LocalDateSchema = z.string().date().transform((arg, ctx) => {
    const date = new Date(arg)

    if (isNaN(date.getTime())) {
        ctx.addIssue({
            code: "invalid_date",
            message: `Invalid date: ${arg}`,
        })

        return z.NEVER
    }

    date.setTime(date.getTime() + date.getTimezoneOffset() * 60_000)

    return date
})
