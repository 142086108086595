import { ADMIN_USER_ROLE, MANAGER_USER_ROLE,
         ACCOUNTANT_USER_SPECIALIZATION, AGENT_USER_ROLE,
         LAWYER_USER_SPECIALIZATION, SUPER_ADMIN_USER_ROLE } from "model/domain"

import { WithUserRights, WithUserRightsFields } from "model/interfaces"
import { Constructor } from "my-util"

export function WithUserRightsMixin<BaseConstructor extends Constructor<WithUserRightsFields>>(
    Base: BaseConstructor,
) {
    return class extends Base implements WithUserRights {
        // Role

        get isClient(): boolean {
            return true
        }

        get isAgent(): boolean {
            return [
                SUPER_ADMIN_USER_ROLE, ADMIN_USER_ROLE,
                MANAGER_USER_ROLE, AGENT_USER_ROLE,
            ].includes(this.role)
        }

        get isManager(): boolean {
            return [SUPER_ADMIN_USER_ROLE, ADMIN_USER_ROLE, MANAGER_USER_ROLE].includes(this.role)
        }

        get isAdmin(): boolean {
            return [SUPER_ADMIN_USER_ROLE, ADMIN_USER_ROLE].includes(this.role)
        }

        get isSuperAdmin(): boolean {
            return this.role === SUPER_ADMIN_USER_ROLE
        }

        // Specialization

        get hasSpecialization(): boolean {
            return this.isAdminOrManagerAnd(this.specialization != null)
        }

        get isLawyer(): boolean {
            return this.isAdminOrManagerAnd(this.specialization === LAWYER_USER_SPECIALIZATION)
        }

        get isAccountant(): boolean {
            return this.isAdminOrManagerAnd(this.specialization === ACCOUNTANT_USER_SPECIALIZATION)
        }

        // Has right

        get hasRightToManageTransfers(): boolean {
            return this.isAdminOrManagerAnd(this.canManageTransfers)
        }

        get hasRightToManageInvites(): boolean {
            return this.isAdminOrManagerAnd(this.canManageInvites)
        }

        get hasRightToSendInvites(): boolean {
            return this.role === AGENT_USER_ROLE || this.isAdminOrManagerAnd(this.canSendInvites)
        }

        get hasRightToManageUsers(): boolean {
            return this.isAdminOrManagerAnd(this.canManageUsers)
        }

        get hasRightToManageProviders(): boolean {
            return this.isAdminOrManagerAnd(this.canManageProviders)
        }

        get hasRightToSeeAllUsers(): boolean {
            return this.isAdminOrManagerAnd(this.canSeeAllUsers)
        }

        private isAdminOrManagerAnd(value: boolean): boolean {
            return this.isAdmin || (this.role === MANAGER_USER_ROLE && value)
        }
    }
}
