import { ForwardedRef, forwardRef, ReactNode, useEffect, useMemo, useState } from "react"
import { DeepReadonly, generateArray } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Carousel } from "ui/ui/input"
import { Flex } from "ui/ui/layout"
import { ErrorDisplay, ErrorText, Loading } from "ui/ui/output"

export namespace PagedView {
    export interface Props extends Flex.Props {
        renderPage?: (index: number, total: number, signal: AbortSignal) => (ReactNode | Promise<ReactNode>)

        total?: number
        selected?: number

        apiErrorMessageMapping?: ErrorText.ApiErrorMessageMapping
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PagedView = forwardRef((
    props: DeepReadonly<PagedView.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const {
        renderPage,
        total, selected,
        apiErrorMessageMapping,
    } = props

    const [page, setPage] = useState<ReactNode>()
    const [renderingPage, setRenderingPage] = useState(true)
    const [pageRenderingError, setPageRenderingError] = useState<unknown>(undefined)

    const innerTotal = total ?? 0
    const [innerSelected, setInnerSelected] = useStateWithDeps(() => selected ?? 0, [selected])

    const pageNumbers = useMemo(
        () => generateArray(innerTotal, i => i + 1),
        [innerTotal],
    )

    // Effects

    useEffect(
        () => {
            if (!renderingPage)
                return

            const controller = new AbortController()

            ;(async () => await renderPage?.(innerSelected, innerTotal, controller.signal))()
                .then(setPage)
                .then(() => setPageRenderingError(undefined))
                .catch(error => {
                    if (!controller.signal.aborted)
                        setPageRenderingError(error)
                })
                .finally(() => {
                    if (!controller.signal.aborted)
                        setRenderingPage(false)
                })

            return () => controller.abort()
        },

        [innerSelected, innerTotal, renderingPage, renderPage],
    )

    // Render

    return <Flex { ...props }
                 ref={ref}>
        {renderContent()}

        {innerTotal > 1 &&
            <Carousel onSelect={onSelect}
                      selected={innerSelected}
                      items={pageNumbers}/>
        }
    </Flex>

    function renderContent(): ReactNode {
        if (renderingPage)
            return <Loading/>

        if (pageRenderingError != null)
            return <ErrorDisplay apiErrorMessageMapping={apiErrorMessageMapping}
                                 error={pageRenderingError}/>

        return page
    }

    // Events

    function onSelect(newInnerSelected: number) {
        setInnerSelected(newInnerSelected)
        setRenderingPage(true)
        setPageRenderingError(false)
    }
})

PagedView.displayName = "Paged"
