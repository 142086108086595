import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { createSubsystem, SubsystemRequest } from "api"
import { Subsystem } from "model"
import { DeepReadonly } from "my-util"
import { SubsystemActionModal } from "../SubsystemActionModal"

export namespace SubsystemCreationModal {
    export interface Props {
        onCreated?: (subsystem: Subsystem) => void
        onClose?: () => void

        closeOnSuccess?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubsystemCreationModal = forwardRef((
    {
        onCreated, onClose,
        closeOnSuccess,
        width,
    }: DeepReadonly<SubsystemCreationModal.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <SubsystemActionModal header={t("domain.subsystems.headers.creation")}
                                 yes={t("misc.actions.save")}
                                 no={t("misc.actions.cancel")}

                                 onYes={onInnerCreate}
                                 onNo={onClose}

                                 closeOnSuccess={closeOnSuccess}

                                 apiErrorMessageMapping={{
                                     409: t("domain.subsystems.messages.errors.keyDuplicate")
                                 }}

                                 width={width}

                                 ref={ref}/>

    async function onInnerCreate(subsystem: Subsystem) {
        const request: SubsystemRequest = {
            key: subsystem.key,
            name: subsystem.name,
            address: subsystem.address,
        }

        const createdSubsystem = await createSubsystem(request)

        onCreated?.(createdSubsystem)
    }
})

SubsystemCreationModal.displayName = "SubsystemCreationModal"
