import { ForwardedRef, forwardRef } from "react"
import { clockIconUrl } from "images"
import { dateToTimeString, DeepReadonly } from "my-util"
import { Output } from "ui/ui/output"

export namespace TimeOutput {
    export interface Props extends Omit<Output.Props, "children"> {
        date: Date
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimeOutput = forwardRef((
    props: DeepReadonly<TimeOutput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Output iconSrc={clockIconUrl}
            iconAlt="Clock icon"
            iconFilter="brightness(0) saturate(100%) invert(71%) sepia(5%) saturate(5137%) hue-rotate(183deg) brightness(68%) contrast(104%)"
            overflow="hidden"
            {...props as TimeOutput.Props}
            ref={ref}>
        {dateToTimeString(props.date)}
    </Output>
)

TimeOutput.displayName = "TimeOutput"
