import { ForwardedRef, forwardRef, ReactNode } from "react"
import { crossIconUrl } from "images"
import { DeepReadonly } from "my-util"
import { Dim } from "ui/ui/appearance"
import { Button as ButtonComponent } from "ui/ui/input"
import { Flex, Center } from "ui/ui/layout"
import { Form } from "ui/ui/structure/forms"
import style from "./style.module.css"

export namespace Modal {
    export interface Props {
        onClose?: () => void

        header?: string
        children?: ReactNode
        buttons?: Button[]

        loading?: boolean
        disabled?: boolean

        opacity?: number
        width?: string
    }

    export interface Button extends ButtonComponent.Props {}
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Modal = forwardRef((
    {
        onClose,
        header, children, buttons,
        loading, disabled,
        opacity, width
    }: DeepReadonly<Modal.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <div className={style.Modal}
                ref={ref}>
        <Dim onClick={onInnerClose}
             opacity={opacity}/>

        <Center type="flex">
            <div className={style.container}
                 style={{ width }}>
                <Form loading={loading}>
                    {renderHeader()}
                    {renderContent()}
                    {renderButtons()}
                </Form>
            </div>
        </Center>
    </div>

    function renderHeader(): ReactNode {
        return <div className={style.header}>
            <Flex justify="space-between"
                  direction="row">
                <div className={style.headerText}>
                    {header}
                </div>

                <ButtonComponent onClick={onInnerClose}

                                 loading={loading}
                                 disabled={disabled}

                                 buttonStyle="text"
                                 width="fit-content"

                                 iconSrc={crossIconUrl}
                                 iconAlt="Cross icon"/>
            </Flex>
        </div>
    }

    function renderContent(): ReactNode {
        return <div className={style.content}>
            {children}
        </div>
    }

    function renderButtons(): ReactNode {
        if (!buttons?.length)
            return null

        return <div className={style.buttons}>
            <Flex direction="row"
                  justify="end"
                  gap="8px">
                {buttons.map((button, i) =>
                    <ButtonComponent width="fit-content"
                                     loading={loading}
                                     disabled={disabled}

                                     {...button}

                                     key={i}/>
                )}
            </Flex>
        </div>
    }

    // Events

    function onInnerClose() {
        if (!loading && !disabled)
            onClose?.()
    }
})

Modal.displayName = "Modal"
