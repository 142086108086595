import { LegalDocumentMessage } from "api"
import { LegalDocument } from "model"
import { DeepReadonly } from "my-util"
import { copyUiDocument, FieldDocument } from "ui/component"

export interface LegalDocumentFields {
    number: string
    date: Date
    document: FieldDocument | null
}

export function copyLegalDocumentFields(
    {
        number,
        date,
        document,
    }: DeepReadonly<LegalDocumentFields>): LegalDocumentFields {
    return {
        number,
        date: new Date(date.getTime()),

        document: document != null
            ? copyUiDocument(document)
            : null,
    }
}

export function legalDocumentFromFields(fields: DeepReadonly<LegalDocumentFields>): LegalDocument {
    return new LegalDocument({
        number: fields.number,
        date: fields.date,
        documentId: fields.document?.document?.id ?? fields.document?.id,
    })
}

export function legalDocumentToFields(document: LegalDocument): LegalDocumentFields {
    return {
        number: document.number,
        date: new Date(document.date.getTime()),

        document: document.documentId != null
            ? {
                status: "loading",
                id: document.documentId,
            }
            : null,
    }
}

export function legalDocumentFieldsToMessage(fields: DeepReadonly<LegalDocumentFields>): LegalDocumentMessage {
    const { number, date, document } = fields

    return {
        number: number,
        date: `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T00:00:00.000Z` as any,
        documentId: document?.document?.id ?? document?.id,
    }
}
