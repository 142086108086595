import { z } from "zod"

import { ADMIN_USER_ROLE, AGENT_USER_ROLE,
         CLIENT_USER_ROLE, MANAGER_USER_ROLE, SUPER_ADMIN_USER_ROLE } from "model"

export const UserRoleResponseSchema = z.enum([
    CLIENT_USER_ROLE,
    AGENT_USER_ROLE,
    MANAGER_USER_ROLE,
    ADMIN_USER_ROLE,
    SUPER_ADMIN_USER_ROLE,
])

export type UserRoleResponse = z.infer<typeof UserRoleResponseSchema>
