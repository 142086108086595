import { t } from "i18next"

// Types

export type UserRole =
    | SuperAdminUserRole
    | AdminUserRole
    | ManagerUserRole
    | AgentUserRole
    | ClientUserRole

export type SuperAdminUserRole = typeof SUPER_ADMIN_USER_ROLE
export type AdminUserRole = typeof ADMIN_USER_ROLE
export type ManagerUserRole = typeof MANAGER_USER_ROLE
export type AgentUserRole = typeof AGENT_USER_ROLE
export type ClientUserRole = typeof CLIENT_USER_ROLE

// Consts

export const SUPER_ADMIN_USER_ROLE = "super-admin"
export const ADMIN_USER_ROLE = "admin"
export const MANAGER_USER_ROLE = "manager"
export const AGENT_USER_ROLE = "agent"
export const CLIENT_USER_ROLE = "client"

// Name

export function getUserRoleName(role: UserRole): string {
    return t(`domain.users.roles.${role}`)
}

// Is-check

export function isUserRole(s: string): s is UserRole {
    switch (s) {
        case CLIENT_USER_ROLE:
        case AGENT_USER_ROLE:
        case MANAGER_USER_ROLE:
        case ADMIN_USER_ROLE:
        case SUPER_ADMIN_USER_ROLE:
            s satisfies UserRole
            return true

        default:
            return false
    }
}

// Abbr

export function getUserRoleAbbr(role: UserRole): string {
    return t(`domain.users.roles.abbrs.${role}`)
}

// Color

export const CLIENT_USER_ROLE_COLOR = "#00A2FF"
export const AGENT_USER_ROLE_COLOR = "green"
export const MANAGER_USER_ROLE_COLOR = "red"
export const ADMIN_USER_ROLE_COLOR = "red"
export const SUPER_ADMIN_USER_ROLE_COLOR = "red"
export const UNKNOWN_USER_ROLE_COLOR = "purple"

export function getUserRoleColor(role: UserRole): string {
    switch (role) {
        case SUPER_ADMIN_USER_ROLE:
            return SUPER_ADMIN_USER_ROLE_COLOR

        case ADMIN_USER_ROLE:
            return ADMIN_USER_ROLE_COLOR

        case MANAGER_USER_ROLE:
            return MANAGER_USER_ROLE_COLOR

        case AGENT_USER_ROLE:
            return AGENT_USER_ROLE_COLOR

        case CLIENT_USER_ROLE:
            return CLIENT_USER_ROLE_COLOR

        default:
            return UNKNOWN_USER_ROLE_COLOR
    }
}
