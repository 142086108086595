import { collapseWhiteSpaceToNull, ReadonlyDate,
         DeepReadonly, Nullish, Copyable, tryCopyDate } from "my-util"

export namespace LegalDocumentMeta {
    export interface OptionsBase extends Nullish<{
        number: string
        date: Date
    }> {}

    export interface CreationOptions extends OptionsBase {}

    export interface CopyOptions extends OptionsBase {}
}

export class LegalDocumentMeta extends Copyable<LegalDocumentMeta.CopyOptions> {
    static createOrPass(
        arg?: LegalDocumentMeta | DeepReadonly<LegalDocumentMeta.CreationOptions> | null,
    ): LegalDocumentMeta {
            return arg instanceof LegalDocumentMeta
                ? arg
                : new LegalDocumentMeta(arg ?? {})
        }

    // Fields

    readonly number: string | null
    readonly date: ReadonlyDate | null

    // Constructor

    constructor(options: DeepReadonly<LegalDocumentMeta.CreationOptions> = {}) {
        super()

        this.number = collapseWhiteSpaceToNull(options.number ?? "")
        this.date = tryCopyDate(options.date)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<LegalDocumentMeta.CopyOptions> = {},
    ): LegalDocumentMeta {
        return new LegalDocumentMeta({
            number: "number" in options
                ? options.number
                : this.number,

            date: "date" in options
                ? options.date
                : this.date,
        })
    }
}
