import { Document } from "model"
import { DeepReadonly } from "my-util"

export function isFieldDocument(document: unknown): document is FieldDocument {
    if (typeof document !== "object" ||
        document == null ||
        !("status" in document))
        return false


    switch (document.status) {
        case "ready":
            return "document" in document
                && document.document instanceof Document

        case "loading":
            return true

        default:
            return false
    }
}

export function copyUiDocument<T extends DeepReadonly<UiDocumentBase>>(
    document: T,
): Extract<UiDocument, { status: T["status"] }> {
    return {
        id: document.id,
        status: document.status,
        document: document.document,
        file: document.file,
    } as any
}

export type FieldDocument =
    | LoadingUiDocument
    | ReadyUiDocument

export type UiDocument =
    | UploadingUiDocument
    | FailedToUploadUiDocument
    | LoadingUiDocument
    | FailedToLoadUiDocument
    | ReadyUiDocument
    | RemovedUiDocument
    | AbortedUiDocument

export interface UploadingUiDocument extends UiDocumentBase {
    status: "uploading"
    file: File
}

export interface FailedToUploadUiDocument extends UiDocumentBase {
    status: "uploading-failed"
    file: File
    error: unknown
}

export interface LoadingUiDocument extends UiDocumentBase {
    status: "loading"
}

export interface FailedToLoadUiDocument extends UiDocumentBase {
    status: "loading-failed"
    error: unknown
}

export interface ReadyUiDocument extends UiDocumentBase {
    status: "ready"
    document: Document
}

export interface RemovedUiDocument extends UiDocumentBase {
    status: "removed"
}

export interface AbortedUiDocument extends UiDocumentBase {
    status: "aborted"
    file: File
}

export interface UiDocumentBase {
    id: string
    status: UiDocumentStatus
    document?: Document
    file?: File
}

export type UiDocumentStatus =
    | "uploading"
    | "uploading-failed"
    | "loading"
    | "loading-failed"
    | "ready"
    | "removed"
    | "aborted"
