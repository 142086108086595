import { z } from "zod"

export function createPageResponseSchema<ItemType extends z.ZodTypeAny>(elementSchema: ItemType) {
    return z.object({
        content: elementSchema.array(),
        number: z.number().nullish(),
        totalPages: z.number().nullish(),
        totalElements: z.number().nullish(),
    })
}

export type PageResponse<T> =
    Omit<z.infer<ReturnType<typeof createPageResponseSchema>>, "content"> &
    { content: T[] }
